import { Button } from '@material-ui/core'
//import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../shared/store'
import {
  getSessionStatus,
  modifyUserData,
} from '../../../shared/store/auth/actions'
import { useRegisterStyles } from '../RegisterPage.styles'

export default function HintIntegration(props) {
  //   const {
  //     formField: {
  //       firstName,
  //       lastName,
  //       address1,
  //       address2,
  //       city,
  //       state,
  //       zipcode,
  //       country,
  //       useAddressForPaymentDetails,
  //     },
  //   } = props
  const [status, setStatus] = useState(null)
  const [customerEmail, setCustomerEmail] = useState('')
  const dispatch = useAppDispatch()
  const history = useHistory()

  const auth = useAppSelector((state) => state.auth)
  //delete once used
  console.log(auth, dispatch, customerEmail, status)
  useEffect(() => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const sessionId = urlParams.get('session_id')
    console.log('sessionId')

    console.log(sessionId)
    //another shitty way to do this and should just use redux but in a rush
    if (sessionId !== null) {
      const getSession = async () => {
        const response: any = await dispatch(
          getSessionStatus({ sessionId: sessionId })
        )
        console.log(response)
        console.log('response')
        setStatus(response?.status)
        setCustomerEmail(response?.customerEmail)
        console.log('status is ', status, customerEmail)
        console.log(status, customerEmail)
      }
      getSession()
    }
    if (status === 'complete' && auth?.userdata?.activationStep !== 2) {
      if (auth && auth?.userdata) {
        const userData = {
          userID: auth?.userdata?.id,
          activationStep: 2,
        }
        dispatch(modifyUserData(userData))
      }
    } else if (status === 'open') {
      history.push('/register/hint/clinical?step=1')
    }
  }, [status])

  const classes = useRegisterStyles()
  const redirectToHint = () => {
    //TODO: Can leave as is and just manually change as needed or update to env vars
    window.location.href = 'https://app.hint.com/admin/integrations/thea-health'
  }
  if (status === 'complete' && auth?.userdata?.activationStep !== 2) {
    if (auth && auth?.userdata) {
      const userData = {
        userID: auth?.userdata?.id,
        activationStep: 2,
      }
      //const userData = auth.userdata
      //userData.activationStep = 2
      console.log('userData')

      console.log(userData)
      //dispatch(modifyUserData(userData))
    }
  } else if (status === 'open') {
    // TODO test how this looks and if it makes sense when redirected
    history.push('/register/hint/clinical?step=2')
  }

  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          style={{ textAlign: 'center', margin: '10px 0' }}
        >
          Integrate with Hint
        </Typography>
        <Typography
          maxWidth="sm"
          style={{ textAlign: 'center', margin: '10px 0' }}
        >
          In order to get information about your patients we need to integrate
          with your Hint account. The button below will take you to Hint. Click
          "Integrate with Thea Health" to complete the integration. Once you've
          done that you'll be brought back here automatically.
        </Typography>
        <Button color="primary" variant="contained" onClick={redirectToHint}>
          Integrate with Hint Core
        </Button>
      </div>
    </React.Fragment>
  )
}
