import axios from 'axios'
import { v4 } from 'uuid'

import { socketInstanceClient } from '../../../shared/utils/socket'
import { getServerUrl } from '../../utils/functions'
import { checkAuthorization } from '../auth/actions'
import {
  IModifyCaseOnSendChatMessage,
  modifyCaseOnSendChatMessage,
} from '../case/actions'
import { AppDispatch, RootState } from '../store'
import { GET_ERRORS, READ_MESSAGES } from './types'

export interface ISendChatMessage {
  channelId: string
  type: string
  content: string
}
export const sendChatMessage =
  (messageData: ISendChatMessage) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    const state = getState()
    const { channelId, type, content } = messageData
    const receiverIds =
      state.case.currentCase.senderData.length > 0
        ? state.case.currentCase.senderData.map((data: any) => data.id)
        : []

    // add pcp/provider id in receiverIds
    // in case of specialist is responding to case
    if (state.auth.userdata.userType === 'Specialty') {
      const providerId = state.case.currentCase.caseData.providerId.toString()
      if (!receiverIds.includes(providerId)) {
        receiverIds.push(providerId)
      }
    }
    const newMessageData = {
      channelId,
      type,
      content,
      senderId: state.auth.userdata.id,
      receiverIds: receiverIds,
      userType: state.auth.userdata.userType,
      specialistId: state.case.currentCase.caseData.latestAssignedSpecialistId,
    }

    axios
      .post(getServerUrl() + '/api/v1/inbox/saveNewInbox', newMessageData)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          socketInstanceClient.message(newMessageData.channelId, {
            ...newMessageData,
            id: v4(),
            firstName: state.auth.userdata.firstName,
            lastName: state.auth.userdata.lastName,
            created: res.data?.created,
          })

          const newCaseData: IModifyCaseOnSendChatMessage = {
            caseId: newMessageData.channelId,
            lastUpdatedById: state.auth.userdata.id,
            isRead: true,
            questions: newMessageData.content,
            isDeleted: false,
            currentUserRole: state.auth.userdata.userType,
          }

          if (state.auth.userdata.userType === 'PCP') {
            const { caseData } = state.case.currentCase
            if (
              caseData.isReadByOther.includes(
                caseData.latestAssignedSpecialistId
              )
            ) {
              newCaseData.isReadByOther = caseData.isReadByOther.filter(
                () => !caseData.latestAssignedSpecialistId
              )
            }
          } else if (state.auth.userdata.userType === 'Specialty') {
            newCaseData.isRead = false
            dispatch(modifyCaseOnSendChatMessage(newCaseData))
          } else {
            dispatch({
              type: GET_ERRORS,
              payload: res.data.message,
            })
          }

          dispatch(modifyCaseOnSendChatMessage(newCaseData))
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((_err) =>
        dispatch({
          type: GET_ERRORS,
          payload: 'err.response.data',
        })
      )
  }

//Read messages with channel data
export const readMessages =
  (channelData: { channelId: string; type: string }) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/inbox/readInbox', channelData)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          const { data } = res.data
          dispatch({
            type: READ_MESSAGES,
            payload: data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }
