import { Button } from '@material-ui/core'

import Typography from '@mui/material/Typography'
import React from 'react'
import { useRegisterStyles } from '../RegisterPage.styles'

export default function UserInfoForm(props) {
  //   const {
  //     formField: {
  //       firstName,
  //       lastName,
  //       address1,
  //       address2,
  //       city,
  //       state,
  //       zipcode,
  //       country,
  //       useAddressForPaymentDetails,
  //     },
  //   } = props
  console.log('UserInfoForm')

  const classes = useRegisterStyles()
  const redirectToWebstore = () => {
    window.location.href =
      'https://chromewebstore.google.com/detail/thea-health-extension/jambipljholmgfegcingicdgkjggipmc'
  }
  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          style={{ textAlign: 'center', margin: '10px 0' }}
        >
          Download the extension
        </Typography>
        <Typography
          maxWidth="sm"
          style={{ textAlign: 'center', margin: '10px 0' }}
        >
          In order to create consults from your EMR, you need to download the
          Thea Health Chrome extension. Click the button below to be taken to
          the Chrome Webstore. You'll be brought back here once you've
          downloaded the extension.
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={redirectToWebstore}
        >
          {' '}
          Download the Extension
        </Button>
      </div>
    </React.Fragment>
  )
}
