import {
  Card,
  Divider,
  Fab,
  Grid,
  InputBase,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from '../../shared/assets/styles/InboxStyles'
import { setCurrentCase } from '../../shared/store/case/actions'
import _ from '../../shared/utils/lodashExtensions'
import ConsultList from '../Inbox/ConsultList'

class AssignConsultPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentCase: null,
      specialtyData: [],
      searchResultsForSpecilty: [],
      assignedUsers: [],
    }
  }

  handleSearch = (e) => {
    e.preventDefault()
    this.setState({
      [e.target.name]: e.target.value,
    })
    let searchedValue = e.target.value
    const { specialtyData } = this.state
    if (searchedValue !== '') {
      let searchResultsForSpecilty = _.filter(specialtyData, function (item) {
        let consultName
        if (item.firstName && item.lastName) {
          consultName = `${item.firstName} ${item.lastName}`
        } else {
          consultName = item.firstName
        }
        return (
          consultName.toLowerCase().indexOf(searchedValue.toLowerCase()) > -1
        )
      })
      this.setState({ searchResultsForSpecilty })
    } else {
      this.setState({
        searchResultsForSpecilty: specialtyData,
      })
    }
  }

  componentDidMount() {
    const { caseId, type } = this.props.match.params
    const currentCaseCondition = {
      caseId,
      currentUserRole: this.props.userdata.userType,
    }
    this.props.setCurrentCase(currentCaseCondition)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { specialtyData, currentCase, errors } = nextProps
    if (errors) {
      this.setState({
        errors,
      })
    }
    if (nextProps.currentCase) {
      let currentCaseData = currentCase ? currentCase.caseData : null
      let assignedUsers = currentCase ? currentCase.assignedUsers : []
      this.setState({
        currentCaseData,
        specialtyData,
        assignedUsers,
        searchResultsForSpecilty: specialtyData,
      })
    }
  }

  render() {
    const { classes, match } = this.props
    const { currentCaseData, searchResultsForSpecilty, assignedUsers } =
      this.state
    let rows = [...searchResultsForSpecilty]
    let route = '/inbox/cases'

    let selectedConsult = []
    if (assignedUsers) {
      assignedUsers.forEach((user) => {
        const userId = user.userId.toString()
        selectedConsult.push(userId)
      })
    }

    return (
      <div className={classes.container}>
        <Grid
          container
          justifyContent="flex-start"
          spacing={0}
          className={classes.cardContainer}
        >
          <Grid item xs={12}>
            <Card
              style={{
                backgroundColor: '#ffffff',
                padding: 20,
                overflow: 'auto',
                height: '100%',
              }}
            >
              <Grid item xs={7}>
                <div style={{ display: 'flex' }}>
                  <Link to={route} data-selector="back-to-inbox">
                    <Fab
                      color="inherit"
                      aria-label="add"
                      style={{
                        width: 38,
                        height: 38,
                        backgroundColor: '#fff',
                      }}
                    >
                      <ArrowBackIcon color="action" />
                    </Fab>
                  </Link>
                  <Typography
                    color="textSecondary"
                    variant="h6"
                    style={{ margin: 5, marginLeft: 10 }}
                  >
                    Back to Inbox
                  </Typography>
                </div>
              </Grid>

              <Grid container justifyContent="center">
                <Grid item xs={6} style={{ padding: 15, paddingLeft: 30 }}>
                  <Typography color="textSecondary" variant="h5">
                    {currentCaseData &&
                      currentCaseData.patientName &&
                      `Assign ${currentCaseData.specialty
                        .map((item) => item.title)
                        .join(', ')} Specialist to ${
                        currentCaseData.patientName
                      }`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div className={classes.search}>
                    <div className={classes.searchIcon}>
                      <SearchIcon style={{ color: '#7e8fa7' }} />
                    </div>
                    <InputBase
                      placeholder="Search by specialist name..."
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'Search' }}
                      onChange={this.handleSearch}
                    />
                    <Divider variant="middle" />
                  </div>
                </Grid>
              </Grid>
              {currentCaseData && (
                <ConsultList
                  rows={rows}
                  patientName={currentCaseData && currentCaseData.patientName}
                  currentCaseData={currentCaseData}
                  params={match.params}
                  selectedConsult={selectedConsult}
                />
              )}
            </Card>
          </Grid>
        </Grid>
      </div>
    )
  }
}

AssignConsultPage.propTypes = {
  userdata: PropTypes.object.isRequired,
  currentCase: PropTypes.any.isRequired,
  setCurrentCase: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  userdata: state.auth.userdata,
  currentCase: state.case.currentCase,
  specialtyData:
    state.case?.currentCase?.caseData?.specialistsWithSpecialty || [],
})

export default connect(mapStateToProps, { setCurrentCase })(
  withStyles(styles)(AssignConsultPage)
)
