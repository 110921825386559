import Link from '@mui/material/Link'

import Typography from '@mui/material/Typography'
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useAppDispatch, useAppSelector } from '../../../shared/store'

import React, { useEffect } from 'react'

//import { fetchClientSecret } from '../../../shared/store/auth/actions'
import {
  createCheckoutSession,
  getPublicStripeKey,
} from '../../../shared/store/auth/actions'
import { useRegisterStyles } from '../RegisterPage.styles'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// This is your test public API key.

// let key = getPublicStripeKey()
// const stripePromise = loadStripe(key)
//let key = await this.props.getPublicStripeKey()
//let stripePromise = await loadStripe(key)
// const stripePromise = loadStripe(
//   'pk_test_51Owq8pDPuIGh5VynCVYQQcTA0H7A7CwfqikgYiUd8kYvXwdUQimOiJy7gbnCgec8GRf1HY6x1R751pxy9j0s9S6N00o46CMkxN'
// )

// const Return = () => {
//   const [status, setStatus] = useState(null);
//   const [customerEmail, setCustomerEmail] = useState('');

//   useEffect(() => {
//     const queryString = window.location.search;
//     const urlParams = new URLSearchParams(queryString);
//     const sessionId = urlParams.get('session_id');
//     setCustomerEmail("this@worked.com")

//     fetch(`/session-status?session_id=${sessionId}`)
//       .then((res) => res.json())
//       .then((data) => {
//         setStatus(data.status);
//         setCustomerEmail("this2@worked.com");
//       });
//   }, []);

//   if (status === 'complete') {
//     return (
//       <section id="success">
//         <p>
//           We appreciate your business! A confirmation email will be sent to {customerEmail}.

//           If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
//         </p>
//       </section>
//     )
//   }

//   return null;
// }

export default function PaymentInfoForm(props) {
  //   const {
  //     formField: {
  //       firstName,
  //       lastName,
  //       address1,
  //       address2,
  //       city,
  //       state,
  //       zipcode,
  //       country,
  //       useAddressForPaymentDetails,
  //     },
  //   } = props
  const dispatch = useAppDispatch()

  const auth = useAppSelector((state) => state.auth)

  const [stripePromise, setStripePromise] = React.useState(null)

  useEffect(() => {
    const fetchStripeKey = async () => {
      const key: any = await dispatch(getPublicStripeKey())
      console.log('got key ', key)
      console.log(key)
      const stripePromise: any = await loadStripe(key)
      setStripePromise(stripePromise)
    }
    fetchStripeKey()
  }, [])
  // const fetchClientSecret = useCallback(() => {
  //   // Create a Checkout Session
  //   return fetch('http://localhost:4000/api/v1/auth/createCheckoutSession', {
  //     method: 'POST',
  //   })
  //     .then((res) => res.json())
  //     .then((data) => data.clientSecret)
  // }, [])

  const [secret, setSecret] = React.useState('')

  // todo Add use callback to prevent unnecessary rerenders and also just do normal middleware stuff here rather than this
  useEffect(() => {
    const fetchClientSecret = async () => {
      console.log('fetchClientSecret2')
      const response: any = await dispatch(
        createCheckoutSession(auth.practiceData?.stripeId)
      )
      setSecret(response.clientSecret)
    }
    fetchClientSecret()
    //dispatch(createCheckoutSession(auth?.practiceData?.stripeId))
  }, [auth.practiceData])

  const options = { clientSecret: secret }

  const classes = useRegisterStyles()

  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          style={{ textAlign: 'center', margin: '10px 0' }}
        >
          Enter your billing information below
        </Typography>
        <Typography
          maxWidth="sm"
          style={{ textAlign: 'center', marginBottom: '100' }}
        >
          We'll charge this card $60 each time you consult with a specialist.
          There are no upfront or monthly fees to use the service. You can find
          more details in our <Link href="#">Terms of Service</Link>.
        </Typography>
      </div>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </React.Fragment>
  )
}
