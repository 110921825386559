//import Button from '@mui/material/Button'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Paper,
  Typography,
} from '@material-ui/core'
import Stack from '@mui/material/Stack'

//import Typography from '@mui/material/Typography'

//import React from 'react'
import { useHistory } from 'react-router-dom'

//import { useRegisterStyles } from './RegisterPage.styles'
//import * as React from 'react';
import { Step, StepLabel, Stepper } from '@material-ui/core'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useEffect, useState } from 'react'
// import Accordion from '@mui/material/Accordion'
// import AccordionActions from '@mui/material/AccordionActions'
// import AccordionDetails from '@mui/material/AccordionDetails'
// import AccordionSummary from '@mui/material/AccordionSummary'

/********************************
 * This is the landing page for the Hint integration
 *
 *
 ********************************/

const steps = [
  'You have a question about next steps for one of your patients so you open Thea Health in Hint Clinical',
  "You can attach your note or other documents then send your question to one of the 118 subspecialties covered by Thea's panel.",
  'You’ll get a text or email when the specialist responds. You can ask follow up questions or close the case.',
  'We’ll automatically upload documentation to Hint Clinical when you finish the case and charge your card on file $60.',
]

// TODO update these images to match site
const images = [
  'https://theahealth.com/wp-content/uploads/2022/05/manandmark.png',
  'https://theahealth.com/wp-content/uploads/2024/06/Clinical-Case-Creation-Mockup-zoomed-out-1.png',
  'https://theahealth.com/wp-content/uploads/2022/05/New-Notification-1-1140x951.png',
  'https://theahealth.com/wp-content/uploads/2024/06/Clinical-Case-Creation-Mockup-zoomed-out.png',
]

export default function HintLanding() {
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)

  const handleStepClick = (step: number) => {
    setActiveStep(step)
  }

  const incrementStep = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep(activeStep + 1)
    } else {
      setActiveStep(0)
    }
  }

  const handleButtonClick = () => {
    history.push('/register/hint/clinical' + window.location.search)
  }

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(incrementStep, 3000)

    //Clearing the interval
    return () => clearInterval(interval)
  }, [activeStep])

  return (
    <div>
      <Paper
        style={{
          background: 'linear-gradient(to top right, #00bcdb, white)',
          padding: '3rem',
        }}
      >
        <Stack direction="row" spacing={2}>
          <div>
            <Typography
              style={{ fontWeight: 'bold', color: 'white' }}
              variant="h3"
            >
              Send eConsults to Specialists Without Leaving Hint Clinical
            </Typography>
            <Typography style={{ marginBottom: '30px' }} variant="h5">
              Thea Health has partnered with Hint to allow you to ask questions
              of specialists from within your EMR for just $60/consult. Click
              the button below to create your account. It only takes 10 minutes!
            </Typography>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleButtonClick}
            >
              Sign Up Now
            </Button>
          </div>
          <img
            src="https://theahealth.com/wp-content/uploads/2024/06/Clinical-Case-Creation-Mockup-zoomed-out-1.png"
            alt="Thea Hint Clinical GIF"
            style={{ maxWidth: '70%' }}
          />
        </Stack>
      </Paper>
      <Paper
        style={{
          padding: '3rem',
        }}
      >
        <Typography variant="h3">How it works</Typography>
        <Stack direction="row" spacing={2}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={index} onClick={() => handleStepClick(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <img
            src={images[activeStep]}
            style={{ width: 500 }}
            alt="Images of Thea Health in Hint Clinical"
          />
        </Stack>

        <Button variant="contained" color="primary" onClick={handleButtonClick}>
          Sign Up Now
        </Button>
      </Paper>
      <Paper
        style={{
          padding: '3rem',
        }}
      >
        <Typography variant="h5">FAQs</Typography>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            How much does this cost?
          </AccordionSummary>
          <AccordionDetails>
            There are no upfront or monthly fees to use the service. You will be
            charged $60 per specialist consulted. This price includes unlimited
            back and forths with the specialist about that patient.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            How do you select the specialists?
          </AccordionSummary>
          <AccordionDetails>
            Our specialists are all US-based board certified physicians in their
            specialty. Typically they work in academic medical centers. In
            addition to verifying these credentials and the specialists’
            licenses, we conduct thorough live training with all specialists and
            regularly conduct quality audits of our cases. We’re happy to
            provide more details on our Specialty Quality Program on request.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
          >
            How long does it take to get a response?
          </AccordionSummary>
          <AccordionDetails>
            Not long! We have a median response time of under 2 hours and over
            90% of our consults get a response in the same business day.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
          >
            Will the specialists bill insurance for this service?
          </AccordionSummary>
          <AccordionDetails>
            Although these services are billable, for our DPC customers our
            panel will not bill for them.
          </AccordionDetails>
        </Accordion>

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5-content"
            id="panel5-header"
          >
            Are you HIPAA compliant?
          </AccordionSummary>
          <AccordionDetails>
            Security is a key part of our culture. We are HIPAA compliant and
            sign a BAA with every customer.
          </AccordionDetails>
        </Accordion>
      </Paper>
    </div>
  )
}
// const queryString = window.location.search
// const urlParams = new URLSearchParams(queryString)
// const sessionId = urlParams.get('session_id')
// const step = urlParams.get('step')
// console.log(sessionId)
// console.log('LOGG')

//export default HintLanding
