import {
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import { useAppSelector } from '../../shared/store'

const useStyles = makeStyles(() => ({
  root: {},
}))

const CardDetails = (props) => {
  const classes = useStyles()
  const userdata = useAppSelector((state) => state.auth.userdata)
  const portalLink =
    'https://billing.stripe.com/p/login/3cs4iv5v6baJ7nO000?prefilled_email=' +
    userdata.email
  return (
    <div>
      <Card className={classes.root} data-selector="payment-information">
        <CardHeader title="Manage Billing" />
        <Divider />
        <Typography style={{ margin: '1rem' }}>
          Thea Health partners with Stripe to handle billing. You see past
          invoices and update your billing information through their portal by
          clicking the button below.
        </Typography>
        <CardActions
          style={{
            marginTop: '0rem',
            marginLeft: '0.5rem',
            marginBottom: '1rem',
          }}
        >
          <Button
            color="primary"
            target="_blank"
            variant="contained"
            href={portalLink}
          >
            Manage Billing Info
          </Button>
        </CardActions>
      </Card>
    </div>
  )
}

export default CardDetails
