/* eslint no-use-before-define: 0 */
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardHeader,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  //LinearProgress,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import MuiPhoneNumber from 'material-ui-phone-number'
import React, { useEffect } from 'react'
import Dropzone from 'react-dropzone'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  modifyUserData,
  updatePracticeData,
} from '../../shared/store/auth/actions'
import _ from '../../shared/utils/lodashExtensions'
import { removeFileFromS3, uploadFile } from '../../shared/utils/s3'

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1.5em',
  },
  avatar: {
    marginBottom: 20,
    height: 130,
    width: 130,
    flexShrink: 10,
    flexGrow: 0,
  },
  progress: {
    marginTop: 20,
  },
  uploadButton: {
    marginRight: 15,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 15,
    },
  },
  modalContainer: {
    minWidth: 450,
    width: '42%',
    height: '60%',
    outline: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: '27%',
  },
  modalCard: {
    width: '92%',
    height: '90%',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
  },
  modalCloseButton: {
    backgroundColor: '#ffffff',
  },
  modalCardTitle: {
    width: '60%',
    marginBottom: '10%',
    fontSize: 24,
    fontWeight: '600',
    textAlign: 'center',
    color: '#454545',
  },
  modalCardDetail: {
    fontSize: 18,
    fontWeight: '600',
    textAlign: 'center',
    color: '#595959',
    marginTop: '10%',
  },
  errorText: {
    fontSize: 12,
    fontWeight: '500',
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
}) //comment for text editor

const AccountProfile = (props) => {
  const { className, setLoader, isAvatarLoader, ...rest } = props

  const userdata = useAppSelector((state) => state.auth.userdata)
  const practiceData = useAppSelector((state) => state.auth.practiceData)

  const classes = useStyles()
  const dispatch = useAppDispatch()

  const [displayavatarModal, setModalValue] = React.useState(false)
  const [oldFileList, setOldFileList] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [filesUploaded, setFilesUploaded] = React.useState([])
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const [firstName, setFirstName] = React.useState(userdata.firstName)
  const [lastName, setLastName] = React.useState(userdata.lastName)
  const [email, setEmail] = React.useState(userdata.email)
  const [phoneNumber, setPhoneNumber] = React.useState(userdata.phoneNumber)
  const [faxNumber, setFaxNumber] = React.useState(practiceData?.faxNumber)
  const [errors, setErrors] = React.useState({})

  const user = {
    name: userdata.firstName + ' ' + userdata.lastName,
    city: 'Los Angeles',
    country: 'USA',
    timezone: 'GTM-7',
    avatar: userdata.avatar
      ? userdata.avatar.name
      : '../../assets/images/alice.png',
  }

  const acceptedAvatarImg = 'image/*'

  const openavatarModal = () => {
    setModalValue(true)
  }

  const handleClose = () => {
    setModalValue(false)
    setOldFileList([])
    setFilesUploaded([])
    setOpenConfirm(false)
  }

  useEffect(() => {
    if (props.errors) {
      setErrors(props.errors)
    }
  }, [props])

  const uploadavatarFile = async (avatarfile) => {
    setLoader(true)
    setModalValue(false)
    const promise = await Promise.all(
      avatarfile.map(async (file) => {
        return await uploadFile(file)
      })
    )
    promise.map((path) => avatarfile.push(path))

    let userData = {
      userID: userdata.id,
      avatar: avatarfile[1][0],
    }
    dispatch(modifyUserData(userData))

    setOldFileList([])
    setFilesUploaded([])
  }

  const onDrop = (uploadedFiles) => {
    setIsLoading(true)
    const files = _.uniqBy(uploadedFiles.flat(), function (e) {
      return JSON.stringify(e)
    })
    setOldFileList(files)
    setIsLoading(false)

    let fileNames
    if (files && files.length >= 1) {
      fileNames = files.map((file, i) => (
        <li key={i} className={classes.list}>
          <span className={classes.listDot}></span>
          {file.name}
        </li>
      ))
      setFilesUploaded(fileNames)
    }
  }

  const handleRemoveAvatar = () => {
    if (userdata.existedAvatar) {
      setOpenConfirm(true)
    }
  }

  const removeAvatar = async () => {
    if (userdata.existedAvatar) {
      setOpenConfirm(false)
      let userData = {
        userID: userdata.id,
        avatar: null,
      }
      dispatch(modifyUserData(userData))
      await removeFileFromS3(userdata.existedAvatar, 0)
    }
  }

  const firstNameChange = (e) => {
    setFirstName(e.target.value)
  }
  const lastNameChange = (e) => {
    setLastName(e.target.value)
  }
  const emailChange = (e) => {
    setEmail(e.target.value)
  }

  const handlePhoneChange = (phone) => {
    if (phone) {
      setPhoneNumber(phone)
    }
  }

  const handleFaxChange = (fax) => {
    if (fax && fax.length > 12) {
      setFaxNumber(fax)
    }
  }

  const saveUserInfo = async () => {
    let error = {}
    let phone = phoneNumber
    if (phone && phone !== '') {
      phone = phone.replace(/ /g, '')
    }
    if (phone === null || phone.length < 13) {
      error.phone = 'Please enter valid phone number'
    }
    if (firstName === '') {
      error.firstName = 'Please enter the first name'
    }
    if (lastName === '') {
      error.lastName = 'Please enter the last name'
    }
    if (email === '') {
      error.email = 'Please enter the email'
    }
    setErrors(error)

    if (Object.keys(error).length === 0) {
      let userData = {
        userID: userdata.id,
        firstName,
        lastName,
        email,
        phoneNumber: phone,
      }
      dispatch(modifyUserData(userData))

      if (faxNumber && faxNumber !== '') {
        dispatch(
          updatePracticeData({
            practiceId: practiceData.id,
            faxNumber: faxNumber,
          })
        )
      }
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader title="User Information" />
      <Divider />

      {/* <CardContent> */}
      {/* <div className={classes.details}>
          { */}
      <div className={classes.details}>
        {isAvatarLoader ? (
          <CircularProgress
            className={classes.avatar}
            style={{ marginTop: 0, marginRight: 0 }}
            size={10}
            disableShrink
          />
        ) : (
          <Avatar className={classes.avatar} src={user.avatar} />
        )}
        {/*<Typography >
                Name: {user.name}
              </Typography>
              <Typography>
                Email: {auth.email}
              </Typography>
              <Typography>
                {auth.phoneNumber ? 'Phone: ' + auth.phoneNumber : ''}
              </Typography>*/}
      </div>
      {/* }
          </div> */}

      {/*<div className={classes.progress}>
            <Typography variant="body1">Profile Completeness: 70%</Typography>
            <LinearProgress
              value={70}
              variant="determinate"
            />
          </div> */}
      {/* </CardContent> */}

      <CardActions style={{ marginLeft: '0.5rem', marginBottom: '1rem' }}>
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="contained"
          onClick={openavatarModal}
        >
          Upload Picture
        </Button>
        <Button
          className={classes.uploadButton}
          variant="contained"
          onClick={handleRemoveAvatar}
        >
          Remove Picture
        </Button>
      </CardActions>

      <div className={classes.details} style={{ margin: '1.0rem' }}>
        <Grid item xs={12} sm={6}>
          <TextField
            autoComplete="fname"
            name="firstName"
            variant="outlined"
            required
            fullWidth
            id="firstName"
            label="First Name"
            autoFocus
            onChange={firstNameChange}
            value={firstName}
          />
          <Typography color="error" className={classes.errorText}>
            {errors.firstName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            autoComplete="lname"
            style={{ marginTop: '1rem' }}
            onChange={lastNameChange}
            value={lastName}
          />
          <Typography color="error" className={classes.errorText}>
            {errors.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="outlined"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            style={{ marginTop: '1rem' }}
            onChange={emailChange}
            value={email}
          />
          <Typography color="error" className={classes.errorText}>
            {errors.email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <MuiPhoneNumber
            //error={!errors.phone ? false : true }
            name="phoneNumber"
            label="Phone Number"
            defaultCountry={'us'}
            variant="outlined"
            value={phoneNumber}
            onChange={handlePhoneChange}
            //helperText={errors.phone}
            style={{ marginTop: '1rem', width: '100%' }}
          />
          <Typography color="error" className={classes.errorText}>
            {errors.phone}
          </Typography>
        </Grid>
        {userdata.isAdmin === true && (
          <Grid item xs={12} sm={6}>
            <MuiPhoneNumber
              name="faxNumber"
              label="Fax Number"
              defaultCountry={'us'}
              variant="outlined"
              value={faxNumber}
              onChange={handleFaxChange}
              style={{ marginTop: '1rem', width: '100%' }}
            />
            <Typography color="error" className={classes.errorText}>
              {errors.fax}
            </Typography>
          </Grid>
        )}
      </div>
      <CardActions style={{ marginLeft: '0.5rem', marginBottom: '1rem' }}>
        <Button
          className={classes.uploadButton}
          color="primary"
          variant="contained"
          onClick={saveUserInfo}
        >
          Save
        </Button>
      </CardActions>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={displayavatarModal}
        onClose={handleClose}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div className={classes.modalContainer}>
          <Card className={classes.modalCard}>
            <Typography className={classes.modalCardTitle}>
              Upload your avatar
            </Typography>

            <Dropzone
              data-cy="drag-and-drop"
              onDrop={onDrop}
              accept={acceptedAvatarImg}
            >
              {({ getRootProps, getInputProps }) => (
                <section className={classes.container}>
                  <div
                    {...getRootProps({ className: 'dropzone' })}
                    style={{ height: '100%' }}
                  >
                    <input {...getInputProps()} />
                    <div
                      className={classes.dropzone}
                      style={{ textAlign: 'center' }}
                    >
                      <CloudDownloadIcon style={{ width: 80, height: 80 }} />
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>

            <aside style={{ maxHeight: 50, overflow: 'auto' }}>
              {isLoading && (
                <CircularProgress
                  style={{ marginTop: 10, marginLeft: 32, alignSelf: 'center' }}
                  size={20}
                  disableShrink
                />
              )}
              {filesUploaded.length >= 1 && (
                <div>
                  <ul
                    style={{
                      marginLeft: 32,
                      marginRight: 32,
                      marginBottom: 10,
                      marginTop: 10,
                    }}
                  >
                    {filesUploaded}
                  </ul>
                </div>
              )}
            </aside>
            {Boolean(oldFileList.length >= 1) && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  height: 70,
                  alignItems: 'center',
                  padding: 20,
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => uploadavatarFile(oldFileList)}
                >
                  Upload
                </Button>
              </div>
            )}
          </Card>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
              paddingTop: 5,
            }}
          >
            <Fab
              variant="round"
              className={classes.modalCloseButton}
              onClick={handleClose}
            >
              <CloseIcon />
            </Fab>
          </div>
        </div>
      </Modal>

      <Dialog
        open={openConfirm}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {'Please confirm'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to remove this avatar image.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No
          </Button>
          <Button onClick={removeAvatar} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  )
}

export default AccountProfile
