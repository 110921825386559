import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, Redirect, RouteComponentProps } from 'react-router-dom'

import { useAppSelector } from '../../shared/store'
import {
  integrateWithHint,
  modifyUserData,
} from '../../shared/store/auth/actions'

const HintInstantOnPage: React.FC<
  RouteComponentProps<{ hintAuthCode: string }>
> = ({ history, match, location }) => {
  const auth = useAppSelector((state) => state.auth)
  console.log(auth)
  const isAuthenticated = useAppSelector((state) => state.auth.isAuthenticated)
  const practiceId = useAppSelector((state) => state.auth.practiceData.id)
  const isHintIntegrated = useAppSelector(
    (state) => state.auth.hint.isHintIntegrated
  )

  const dispatch = useDispatch()

  const { hintAuthCode } = match.params

  useEffect(() => {
    if (practiceId && hintAuthCode && !isHintIntegrated) {
      dispatch(integrateWithHint({ practiceId, hintAuthCode }, history))
    } else {
      console.log(
        'Why not? ',
        practiceId + ' ' + hintAuthCode + ' ' + isHintIntegrated
      )
    }
  }, [dispatch, history, hintAuthCode, practiceId, isHintIntegrated])

  if (isHintIntegrated) {
    if (auth && auth?.userdata) {
      const userData = {
        userID: auth?.userdata?.id,
        activationStep: 3,
      }
      dispatch(modifyUserData(userData))
    }
    return <Redirect to="/register/hint/clinical?step=3" />
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          width: '60%',
          textAlign: 'center',
          margin: '30px 0',
          fontSize: '20px',
        }}
      >
        {!isAuthenticated || !practiceId ? (
          <>
            <div style={{ padding: '30px' }}>
              <Link
                to={{ pathname: '/login', state: { from: location.pathname } }}
              >
                Log in with your Thea Health account
              </Link>{' '}
              to complete the integration!
            </div>

            <div>
              Don’t have an account with Thea Health? Before you can integrate
              with Hint you’ll need to register.
              <Link to="/register/identification/signUpKey_39085025/isAdmin_true">
                {' '}
                Click here
              </Link>{' '}
              to register now and then start the integration from Hint once you
              have an account.
            </div>
          </>
        ) : (
          'Integrating with TheaHealth...'
        )}
      </div>
    </div>
  )
}

export default HintInstantOnPage
