import axios from 'axios'
import { RouteComponentProps } from 'react-router-dom'

import { ICase } from '../../types'
import { snackbarMessages } from '../../utils/constant'
import { getServerUrl } from '../../utils/functions'
import { getSignedUrl } from '../../utils/s3'
import { checkAuthorization } from '../auth/actions'
import { fetchAllDrafts } from '../draft/actions'
import { closeSnackbar, enqueueSnackbar } from '../snackbar/actions'
import { AppDispatch, RootState } from '../store'
import {
  CASE_LOADER,
  CLEAR_ATHENA_DATA_AND_CURRENT_CASE_DATA,
  CURRENT_CASE,
  FETCH_ALL_CASES,
  GET_ALL_SPECIALTIES_USERS,
  GET_ERRORS,
  GET_NUM_NEW_MESSAGE,
  SET_ATHENA_LATEST_ENCOUNTER_SUMMARY,
  SET_CERBO_LATEST_ENCOUNTER_SUMMARY,
  SET_CURRENT_CASE_PROCEDURE_CODES,
  SET_ELATION_LATEST_ENCOUNTER_SUMMARY,
  SET_PATIENT_ATHENA_DIAGNOSES,
  SET_SEARCHED_DIAGNOSES,
  SET_USER_PATIENT_IS_LOADING,
  SET_USER_PATIENT_LIST,
  SPECIALTY_LIST,
} from './types'

// Send new case to specialty
export const sendCase =
  (caseData: ICase, history: RouteComponentProps['history']) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    axios
      .post(getServerUrl() + '/api/v1/case/saveCase', caseData)
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          // dispatch({
          //   type: SEND_CASE,
          //   payload: res.data.data,
          // })
          // dispatch({
          //   type: ADD_NEW_CASE,
          //   payload: res.data.data,
          // })
          history.push('/inbox/cases')
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.errors,
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: GET_ERRORS,
          payload: err,
        })
      })
      .finally(() => {
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

export const setCurrentCase =
  (request: { caseId: string; currentUserRole: string }) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: CURRENT_CASE,
      payload: {},
    })
    axios
      .post(getServerUrl() + '/api/v1/case/fetchCase', request)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          const { data } = res.data
          dispatch({
            type: CURRENT_CASE,
            payload: data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }

interface IFetchAllCases {
  currentUserId: string
  currentUserPracticeId: string
  currentUserRole: string
  isCompleted: boolean
  isDeleted: boolean
}
//Get all cases from database
export const fetchAllCases =
  (currentUserData: IFetchAllCases) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    axios
      .post(getServerUrl() + '/api/v1/case/fetchAllCases', currentUserData)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          const { data } = res.data
          dispatch({
            type: FETCH_ALL_CASES,
            payload: data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
      .finally(() => {
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

export interface IModifyCase {
  caseId: string
  currentUserRole: string
  isDeleted: boolean
  isRead: boolean
  lastUpdatedById: string
  questions?: string
  token?: string
  isRreadByOther?: string[]
  emailToID?: boolean
  practiceId?: string
  claimStatus?: 'SUBMITTED' | 'NOT SUBMITTED'
}

export const modifyCase =
  (data: IModifyCase) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    const { currentUserRole, ...modifyCaseData } = data
    axios
      .post(getServerUrl() + '/api/v1/case/modifyCaseDataById', modifyCaseData)
      .then((res) => {
        if (res.data.status === '1') {
          const currentCaseCondition = {
            caseId: data.caseId,
            currentUserRole,
          }
          dispatch(setCurrentCase(currentCaseCondition))
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.CASE_STATE_MODIFIED,
              options: {
                variant: 'success',
              },
            })
          )
        } else {
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((_errors) => {
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.INTERNAL_ERROR,
            options: {
              variant: 'error',
            },
          })
        )
      })
      .finally(() => {
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface IModifyCaseOnTableRowClick {
  caseId: string
  currentUserRole: string
  isRead: boolean
  lastUpdatedById: string
}

export const modifyCaseOnTableRowClick =
  (data: IModifyCaseOnTableRowClick) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    axios
      .post(getServerUrl() + '/api/v1/case/modifyCaseDataById', data)
      .then((res) => {
        const { auth } = getState()
        dispatch(
          getNumOfNewMessage({
            userId: auth.userdata.id,
            userRole: auth.userdata.userType,
            practiceId: auth.userdata.practiceId,
          })
        )
        if (res.data.status !== '1') {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }
export interface IModifyCaseOnSendChatMessage {
  caseId: string
  currentUserRole: string
  isDeleted: boolean
  isRead: boolean
  lastUpdatedById: string
  questions?: string
  token?: string
  isReadByOther?: string[]
}
export const modifyCaseOnSendChatMessage =
  (data: IModifyCaseOnSendChatMessage) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/case/modifyCaseDataById', data)
      .then((res) => {
        if (res.data.status !== '1') {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }

interface IGetNumOfNewMessage {
  userId: string
  userRole: string
  practiceId?: string
}
export const getNumOfNewMessage =
  (userData: IGetNumOfNewMessage) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: GET_NUM_NEW_MESSAGE,
      payload: 0,
    })
    axios
      .post(getServerUrl() + '/api/v1/case/getNumberOfNewMessage', userData)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: GET_NUM_NEW_MESSAGE,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }

interface IGetUserPatients {
  currentPracticeId: string
  currentUserId: string
  currentUserRole: string
  keyword: string
}
export const getUserPatients =
  (currentUserData: IGetUserPatients) =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: SET_USER_PATIENT_IS_LOADING,
      payload: true,
    })

    axios
      .post(getServerUrl() + '/api/v1/case/getUserPatients', currentUserData)
      .then((res) => {
        dispatch(checkAuthorization(res))

        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: SET_USER_PATIENT_LIST,
            payload: res.data.data,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
      .finally(() => {
        dispatch({
          type: SET_USER_PATIENT_IS_LOADING,
          payload: false,
        })
      })
  }

export const closeCases =
  (caseData: {
    caseIds: {
      caseId: string
      specialistId?: string
    }[]
    isDeleted?: boolean
    isRead?: boolean
    lastUpdatedById?: string
    practiceId?: string
  }) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    axios
      .post(getServerUrl() + '/api/v1/case/closeCaseByIds', caseData)
      .then((res) => {
        const { auth } = getState()
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch(
            fetchAllCases({
              currentUserId: auth.userdata.id,
              currentUserPracticeId: auth.userdata.practiceId,
              currentUserRole: auth.userdata.userType,
              isCompleted: false,
              isDeleted: false,
            })
          )
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.CASE_STATE_MODIFIED,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
              },
            })
          )
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
      .finally(() => {
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface IReOpenCases {
  caseIds: string[]
  isDeleted: false
  isRead: boolean
  lastUpdatedById: string
}
export const reOpenCases =
  (caseData: IReOpenCases) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    // dispatch({
    //   type: ACTION_PERFORMED,
    //   payload: false,
    // })
    axios
      .post(getServerUrl() + '/api/v1/case/repoenCaseByIds', caseData)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: CASE_LOADER,
            payload: false,
          })
          // dispatch({
          //   type: ACTION_PERFORMED,
          //   payload: true,
          // })
          const {
            auth: { userdata },
          } = getState()
          const currentUserData = {
            currentUserId: userdata.id,
            currentUserRole: userdata.userType,
            isDeleted: '',
            isCompleted: false,
          }
          dispatch(fetchAllCases(currentUserData as any))
          dispatch(fetchAllDrafts(currentUserData as any))

          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.CASE_STATE_MODIFIED,
              options: {
                key: new Date().getTime() + Math.random(),
                variant: 'success',
              },
            })
          )
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }

interface IGetUserSpecialties {
  practiceId: string
  fetchUsers: boolean
}
export const getUserSpecialties =
  (data: IGetUserSpecialties) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(getServerUrl() + '/api/v1/case/getUserSpecialties', data)
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: SPECIALTY_LIST,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }

export const clearAthenaDataAndCurrentCaseData =
  () =>
  (dispatch: AppDispatch): void => {
    dispatch({
      type: CLEAR_ATHENA_DATA_AND_CURRENT_CASE_DATA,
    })
  }

interface ICreateAthenaClinicalDocumentRecordAndModifyCase {
  departmentId: string
  documentSubclass: string
  athenaPracticeId: string
  practiceId: string
  patientId: string
  attachmentContents: File
  caseId: string
}

export const createAthenaClinicalDocumentRecordAndModifyCase =
  ({
    departmentId,
    documentSubclass,
    athenaPracticeId,
    patientId,
    practiceId,
    attachmentContents,
    caseId,
  }: ICreateAthenaClinicalDocumentRecordAndModifyCase) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    const data = new FormData()
    data.append('departmentId', departmentId)
    data.append('documentSubclass', documentSubclass)
    data.append('athenaPracticeId', athenaPracticeId)
    data.append('patientId', patientId)
    data.append('practiceId', practiceId)
    data.append('attachmentContents', attachmentContents)

    const uploadToAthenaKey = 'upload-to-athena-key'
    dispatch(
      enqueueSnackbar({
        message: 'Uploading file to Athena Health.',
        options: {
          key: uploadToAthenaKey,
          variant: 'info',
          persist: true,
        },
      })
    )
    axios
      .post(
        `${getServerUrl()}/api/v1/athena/patients/${patientId}/documents/clinicaldocument`,
        data,
        { timeout: 10000000 }
      )
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch(
            enqueueSnackbar({
              message: 'File uploaded to Athena Health.',
              options: {
                variant: 'success',
              },
            })
          )
          const {
            auth: { userdata },
          } = getState()
          dispatch(
            modifyCase({
              caseId,
              isRead: true,
              emailToID: true,
              isDeleted: true,
              lastUpdatedById: userdata.id,
              practiceId: userdata.practiceId,
              currentUserRole: userdata.userType,
              claimStatus: 'NOT SUBMITTED',
            })
          )
        } else {
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((_errors) => {
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.INTERNAL_ERROR,
            options: {
              variant: 'error',
            },
          })
        )
      })
      .finally(() => {
        dispatch(closeSnackbar(uploadToAthenaKey))
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface ICreateAthenaClinicalDocumentRecord {
  departmentId: string
  documentSubclass: string
  athenaPracticeId: string
  patientId: string
  practiceId: string
  attachmentContents: File
  caseId: string
}

export const createAthenaClinicalDocumentRecord =
  ({
    departmentId,
    documentSubclass,
    athenaPracticeId,
    patientId,
    practiceId,
    attachmentContents,
  }: ICreateAthenaClinicalDocumentRecord) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    const data = new FormData()
    data.append('departmentId', departmentId)
    data.append('documentSubclass', documentSubclass)
    data.append('athenaPracticeId', athenaPracticeId)
    data.append('patientId', patientId)
    data.append('practiceId', practiceId)
    data.append('attachmentContents', attachmentContents)

    const uploadToAthenaKey = 'upload-to-athena-key'
    dispatch(
      enqueueSnackbar({
        message: 'Uploading file to Athena Health.',
        options: {
          key: uploadToAthenaKey,
          variant: 'info',
          persist: true,
        },
      })
    )
    axios
      .post(
        `${getServerUrl()}/api/v1/athena/patients/${patientId}/documents/clinicaldocument`,
        data,
        { timeout: 10000000 }
      )
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch(
            enqueueSnackbar({
              message: 'File uploaded to Athena Health.',
              options: {
                variant: 'success',
              },
            })
          )
        } else {
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((_errors) => {
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.INTERNAL_ERROR,
            options: {
              variant: 'error',
            },
          })
        )
      })
      .finally(() => {
        dispatch(closeSnackbar(uploadToAthenaKey))
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface IGetAthenaLatestEncouterSummary {
  athenaPracticeId: string
  athenaDepartmentId: number
  athenaPatientId: string
}
export const getAthenaLatestEncounterSummary =
  ({
    athenaPracticeId,
    athenaDepartmentId,
    athenaPatientId,
  }: IGetAthenaLatestEncouterSummary) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch({
        type: SET_ATHENA_LATEST_ENCOUNTER_SUMMARY,
        payload: {
          pdfFileObj: null,
          pdfUrl: null,
          isLoading: true,
        },
      })
      const res = await axios.get(
        `${getServerUrl()}/api/v1/athena/latest-encounter-summary`,
        {
          params: {
            athenaDepartmentId,
            athenaPracticeId,
            athenaPatientId,
          },
        }
      )
      const { status } = res.data
      if (status === '1') {
        const fileNamePathSplitted = res.data.data.key.split('/')[1] // key: 'files-for-case/WinstonLeonardCHURCHILL_lastEncounterSummary_1622096106116.pdf',
        const fileName = fileNamePathSplitted.replace(/[^a-zA-Z0-9._]/g, '')
        const signedUrl = await getSignedUrl('get', fileName)

        dispatch({
          type: SET_ATHENA_LATEST_ENCOUNTER_SUMMARY,
          payload: {
            pdfFileObj: {
              name: fileName,
              type: 'application/pdf',
            },
            pdfUrl: signedUrl.data.url,
            isLoading: false,
          },
        })
      } else {
        throw new Error('Error!')
      }
    } catch (errors) {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    } finally {
      dispatch({
        type: SET_ATHENA_LATEST_ENCOUNTER_SUMMARY,
        payload: {
          pdfFileObj: null,
          pdfUrl: null,
          isLoading: false,
        },
      })
    }
  }

interface ITriggerAthenaPatientChangeEvent {
  athenaPracticeId: string
  practiceId: string
}
export const triggerAthenaPatientChangeEvent =
  ({ athenaPracticeId, practiceId }: ITriggerAthenaPatientChangeEvent) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      await axios.get(
        `${getServerUrl()}/api/v1/athena/${athenaPracticeId}/patients/changed?practiceId=${practiceId}`
      )
    } catch (errors) {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    }
  }
interface IGetListOfAthenaProcedureCodes {
  athenaPatientId: string
  athenaPracticeId: string
  practiceId: string
  athenaDepartmentId: number
}
export const getListOfAthenaProcedureCodes =
  ({
    practiceId,
    athenaPatientId,
    athenaPracticeId,
    athenaDepartmentId,
  }: IGetListOfAthenaProcedureCodes) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { data } = await axios.get(
        `${getServerUrl()}/api/v1/athena/${athenaPatientId}/procedurecodes?practiceId=${practiceId}&athenaPracticeId=${athenaPracticeId}&athenaDepartmentId=${athenaDepartmentId}`
      )
      if (data.status === '1') {
        dispatch({
          type: SET_CURRENT_CASE_PROCEDURE_CODES,
          payload: data.data.procedures,
        })
      } else {
        throw new Error('Error!')
      }
    } catch (errors) {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    }
  }

interface ICreateAthenaClaimAndModifyCase {
  athenaPatientId: string
  athenaPracticeId: string
  practiceId: string
  procedureCodeList: string[]
  diagnoseCodeList: string[]
  caseId: string
}
export const createAthenaClaimAndModifyCase =
  ({
    athenaPatientId,
    athenaPracticeId,
    practiceId,
    procedureCodeList,
    diagnoseCodeList,
    caseId,
  }: ICreateAthenaClaimAndModifyCase) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    const data = {
      athenaPracticeId,
      practiceId,
      procedureCodeList,
      diagnoseCodeList,
      departmentId: 21,
    }
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    axios
      .post(
        `${getServerUrl()}/api/v1/athena/patients/${athenaPatientId}/claims`,
        data
      )
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.ATHENA_CLAIM_CREATED_SUCCESS,
              options: {
                variant: 'success',
              },
            })
          )
          const {
            auth: { userdata },
          } = getState()
          dispatch(
            modifyCase({
              caseId,
              isRead: true,
              emailToID: true,
              isDeleted: true,
              lastUpdatedById: userdata.id,
              practiceId: userdata.practiceId,
              currentUserRole: userdata.userType,
              claimStatus: 'SUBMITTED',
            })
          )
        } else {
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.ATHENA_CLAIM_CREATED_FAIL,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((errors) => {
        console.error(errors)
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.ATHENA_CLAIM_CREATED_FAIL,
            options: {
              variant: 'error',
            },
          })
        )
      })
      .finally(() => {
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface ICreateElationDocumentReportRecordAndModifyCase {
  elationPatientId: string
  elationPatientPracticeId: string
  elationPatientPhysicianId: string
  practiceId: string
  base64Content: string
}

export const createElationDocumentReportRecordAndModifyCase =
  ({
    elationPatientId,
    elationPatientPracticeId,
    elationPatientPhysicianId,
    practiceId,
    base64Content,
  }: ICreateElationDocumentReportRecordAndModifyCase) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })

    const data = {
      elationPatientId,
      elationPatientPracticeId,
      elationPatientPhysicianId,
      practiceId,
      base64Content,
    }
    const uploadToElationKey = 'upload-to-elation-key'
    dispatch(
      enqueueSnackbar({
        message: 'Uploading file to Elation Health.',
        options: {
          key: uploadToElationKey,
          variant: 'info',
          persist: true,
        },
      })
    )
    axios
      .post(`${getServerUrl()}/api/v1/elation/createDocumentReport`, data, {
        timeout: 10000000,
      })
      .then((res) => {
        const { status } = res.data
        if (status === '1') {
          dispatch(
            enqueueSnackbar({
              message: 'File uploaded to Elation Health.',
              options: {
                variant: 'success',
              },
            })
          )
          const {
            auth: { userdata },
            case: {
              currentCase: { caseData },
            },
          } = getState()
          const { caseId } = caseData
          dispatch(
            modifyCase({
              caseId,
              isRead: true,
              emailToID: true,
              isDeleted: true,
              lastUpdatedById: userdata.id,
              practiceId: userdata.practiceId,
              currentUserRole: userdata.userType,
              // claimStatus: 'NOT SUBMITTED',
            })
          )
        } else {
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((_errors) => {
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.INTERNAL_ERROR,
            options: {
              variant: 'error',
            },
          })
        )
      })
      .finally(() => {
        dispatch(closeSnackbar(uploadToElationKey))
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface ICloseCerboCaseAndUploadDocument {
  caseId: string
  uploadDocumentData: IUploadDocumentToCerbo
}
export const closeCerboCaseAndUploadDocument =
  ({ caseId, uploadDocumentData }: ICloseCerboCaseAndUploadDocument) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })

    const {
      auth: { userdata },
    } = getState()

    axios
      .post(getServerUrl() + '/api/v1/case/modifyCaseDataById', {
        caseId,
        isRead: true,
        emailToID: true,
        isDeleted: true,
        lastUpdatedById: userdata.id,
        practiceId: userdata.practiceId,
        claimStatus: 'SUBMITTED',
      })
      .then((res) => {
        if (res.data.status === '1') {
          const currentCaseCondition = {
            caseId,
            currentUserRole: userdata.userType,
          }
          dispatch(setCurrentCase(currentCaseCondition))
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.CASE_STATE_MODIFIED,
              options: {
                variant: 'success',
              },
            })
          )
          dispatch(uploadDocumentToCerbo(uploadDocumentData))
        } else {
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((_errors) => {
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.INTERNAL_ERROR,
            options: {
              variant: 'error',
            },
          })
        )
      })
      .finally(() => {
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface IUploadDocumentToCerbo {
  cerboPatientId: string
  practiceId: string
  documentMimeType: string
  documentName: string
  documentBase64Content: string
}
export const uploadDocumentToCerbo =
  (documentData: IUploadDocumentToCerbo) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { cerboPatientId, practiceId, ...bodyData } = documentData
    try {
      //TODO see if this actually works w/ double slash
      const { data } = await axios.post(
        `${getServerUrl()}/api/v1//cerbo/${practiceId}/patient/${cerboPatientId}/uploadDocument`,
        bodyData
      )

      if (data.status === '1') {
        dispatch(
          enqueueSnackbar({
            message: 'File uploaded to Cerbo Health!',
            options: {
              variant: 'success',
            },
          })
        )
      } else {
        dispatch(
          enqueueSnackbar({
            message: data.message,
            options: {
              variant: 'error',
            },
          })
        )
      }
    } catch (errors) {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    }
  }

interface ICloseAllInOneCaseAndUploadDocument {
  caseId: string
  uploadDocumentData: IUploadDocumentToAllInOne
}
export const closeAllInOneCaseAndUploadDocument =
  ({ caseId, uploadDocumentData }: ICloseAllInOneCaseAndUploadDocument) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    console.log('closeAllInOneCaseAndUploadDocument')

    const {
      auth: { userdata },
    } = getState()

    axios
      .post(getServerUrl() + '/api/v1/case/modifyCaseDataById', {
        caseId,
        isRead: true,
        emailToID: true,
        isDeleted: true,
        lastUpdatedById: userdata.id,
        practiceId: userdata.practiceId,
        claimStatus: 'SUBMITTED',
      })
      .then((res) => {
        if (res.data.status === '1') {
          const currentCaseCondition = {
            caseId,
            currentUserRole: userdata.userType,
          }
          dispatch(setCurrentCase(currentCaseCondition))
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.CASE_STATE_MODIFIED,
              options: {
                variant: 'success',
              },
            })
          )
          dispatch(uploadDocumentToAllInOne(uploadDocumentData))
        } else {
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((_errors) => {
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.INTERNAL_ERROR,
            options: {
              variant: 'error',
            },
          })
        )
      })
      .finally(() => {
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface IUploadDocumentToAllInOne {
  hintPatientId: string
  practiceId: string
  documentMimeType: string
  documentName: string
  document: string
}
export const uploadDocumentToAllInOne =
  (documentData: IUploadDocumentToAllInOne) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const { hintPatientId, practiceId, ...bodyData } = documentData
    console.log('trying to uploadDocumentToAllInOne')
    console.log(bodyData)
    console.log(hintPatientId)
    console.log(practiceId)

    axios
      .post(
        `${getServerUrl()}/api/v1/allInOne/${practiceId}/patient/${hintPatientId}/uploadDocument`,
        bodyData
      )
      .then((res) => {
        //dispatch(checkAuthorization(res))
        const { status } = res.data
        console.log('status', status)
        if (status === '1') {
          console.log('success')
          dispatch(
            enqueueSnackbar({
              message: 'File uploaded to All-In-One!',
              options: {
                variant: 'success',
              },
            })
          )
        } else {
          console.error('error')
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((errors) => {
        console.error(errors)
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
    // axios.post(
    //   `${getServerUrl()}/api/v1/allInOne/${practiceId}/patient/${hintPatientId}/uploadDocument`,
    //   bodyData
    // )
    // console.log('data', data)
  }

interface ICloseAkuteCaseAndUploadDocument {
  caseId: string
  uploadDocumentData: IUploadDocumentToAkute
}
export const closeAkuteCaseAndUploadDocument =
  ({ caseId, uploadDocumentData }: ICloseAkuteCaseAndUploadDocument) =>
  (dispatch: AppDispatch, getState: () => RootState): void => {
    dispatch({
      type: CASE_LOADER,
      payload: true,
    })
    console.log('closeAkuteCaseAndUploadDocument')

    const {
      auth: { userdata },
    } = getState()

    axios
      .post(getServerUrl() + '/api/v1/case/modifyCaseDataById', {
        caseId,
        isRead: true,
        emailToID: true,
        isDeleted: true,
        lastUpdatedById: userdata.id,
        practiceId: userdata.practiceId,
        claimStatus: 'SUBMITTED',
      })
      .then((res) => {
        if (res.data.status === '1') {
          const currentCaseCondition = {
            caseId,
            currentUserRole: userdata.userType,
          }
          dispatch(setCurrentCase(currentCaseCondition))
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.CASE_STATE_MODIFIED,
              options: {
                variant: 'success',
              },
            })
          )
          dispatch(uploadDocumentToAkute(uploadDocumentData))
        } else {
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((_errors) => {
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.INTERNAL_ERROR,
            options: {
              variant: 'error',
            },
          })
        )
      })
      .finally(() => {
        dispatch({
          type: CASE_LOADER,
          payload: false,
        })
      })
  }

interface IUploadDocumentToAkute {
  akutePatientId: string
  practiceId: string
  documentMimeType: string
  documentName: string
  document: File
}
export const uploadDocumentToAkute =
  (documentData: IUploadDocumentToAkute) =>
  async (dispatch: AppDispatch): Promise<void> => {
    const {
      akutePatientId,
      practiceId,
      documentMimeType,
      documentName,
      document,
    } = documentData
    const data = new FormData()
    data.append('documentMimeType', documentMimeType) // todo: can probably be removed since we are sending the file
    data.append('documentName', documentName)

    data.append('document', document)

    axios
      .post(
        `${getServerUrl()}/api/v1/akute/${practiceId}/patient/${akutePatientId}/uploadDocument`,
        data,
        {
          timeout: 10000000,
        }
      )
      .then((res) => {
        //dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          console.log('success')
          dispatch(
            enqueueSnackbar({
              message: 'File uploaded to Akute!',
              options: {
                variant: 'success',
              },
            })
          )
        } else {
          console.log('error ', res.data.message)
          dispatch(
            enqueueSnackbar({
              message: res.data.message,
              options: {
                variant: 'error',
              },
            })
          )
        }
      })
      .catch((errors) => {
        console.log(errors)
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
    // axios.post(
    //   `${getServerUrl()}/api/v1/akute/${practiceId}/patient/${akutePatientId}/uploadDocument`,
    //   bodyData
    // )
    // console.log('data', data)
  }

interface IGetDiagnoses {
  term: string
}
export const getDiagnoses =
  ({ term }: IGetDiagnoses) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const { data } = await axios.get(
        `${getServerUrl()}/api/v1/diagnose/icd10codes?term=${term}`
      )
      if (data.status === '1') {
        dispatch({
          type: SET_SEARCHED_DIAGNOSES,
          payload: data.data,
        })
      } else {
        throw new Error('Error!')
      }
    } catch (errors) {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    }
  }

export const getAthenaPatientDiagnoses =
  ({
    athenaPracticeId,
    practiceId,
    athenaDepartmentId,
    athenaPatientId,
  }: {
    athenaPracticeId: string
    practiceId: string
    athenaDepartmentId: number
    athenaPatientId: string
  }) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      const res = await axios.get(
        `${getServerUrl()}/api/v1/athena/diagnoses-from-latest-encounter`,
        {
          params: {
            athenaPracticeId,
            athenaDepartmentId,
            athenaPatientId,
          },
        }
      )
      const { status } = res.data
      if (status === '1') {
        dispatch({
          type: SET_PATIENT_ATHENA_DIAGNOSES,
          payload: res.data.data,
        })
      } else {
        throw new Error('Error!')
      }
    } catch (errors) {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    }
  }

interface ISendCloseCaseFaxPdf {
  practiceId: string
  caseId: string
  attachmentContents: File
}
export const sendCloseCasePdfFax =
  ({ practiceId, caseId, attachmentContents }: ISendCloseCaseFaxPdf) =>
  (dispatch: AppDispatch): void => {
    const data = new FormData()
    data.append('practiceId', practiceId)
    data.append('caseId', caseId)
    data.append('attachmentContents', attachmentContents)

    axios
      .post(`${getServerUrl()}/api/v1/case/sendCloseCaseFax`, data, {
        timeout: 10000000,
      })
      .then((res) => {
        const { status, data } = res.data
        if (status === '1' && data) {
          dispatch(
            enqueueSnackbar({
              message: 'Fax has been sent.',
              options: {
                variant: 'success',
              },
            })
          )
        } else {
          if (res.data.message) {
            dispatch(
              enqueueSnackbar({
                message: res.data.message,
                options: {
                  variant: 'error',
                },
              })
            )
          }
        }
      })
      .catch((_errors) => {
        dispatch(
          enqueueSnackbar({
            message: snackbarMessages.INTERNAL_ERROR,
            options: {
              variant: 'error',
            },
          })
        )
      })
  }

export const getAllSpecialties =
  () =>
  (dispatch: AppDispatch): void => {
    axios
      .get(getServerUrl() + '/api/v1/speciality/getAllSpecialties')
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch({
            type: GET_ALL_SPECIALTIES_USERS,
            payload: res.data.data,
          })
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }

interface ISaveDefaultSpecialist {
  id: number
  defaultSpecialistId?: number
}
export const saveDefaultSpecialist =
  (defaultSpecialist: ISaveDefaultSpecialist) =>
  (dispatch: AppDispatch): void => {
    axios
      .post(
        getServerUrl() + '/api/v1/speciality/saveDefaultSpecialist',
        defaultSpecialist
      )
      .then((res) => {
        dispatch(checkAuthorization(res))
        const { status } = res.data
        if (status === '1') {
          dispatch(getAllSpecialties())
          dispatch(
            enqueueSnackbar({
              message: snackbarMessages.DEFAULT_SPECIALIST_MODIFIED,
              options: {
                variant: 'success',
              },
            })
          )
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: res.data.message,
          })
        }
      })
      .catch((errors) => {
        dispatch({
          type: GET_ERRORS,
          payload: errors,
        })
      })
  }

interface IGetLatestEncouterSummaryCerbo {
  practiceId: string
  cerboPatientId: string
}
export const getLatestEncouterSummaryCerbo =
  ({ cerboPatientId, practiceId }: IGetLatestEncouterSummaryCerbo) =>
  async (dispatch: AppDispatch): Promise<void> => {
    try {
      dispatch({
        type: SET_CERBO_LATEST_ENCOUNTER_SUMMARY,
        payload: {
          pdfFileObj: null,
          pdfUrl: null,
          isLoading: true,
        },
      })
      const res = await axios.get(
        `${getServerUrl()}/api/v1/cerbo/${practiceId}/patient/${cerboPatientId}/getLatestEncouterSummaryCerbo`
      )
      const { status } = res.data
      if (status === '1') {
        const { pdf, files } = res.data.data
        const fileNamePathSplitted = pdf.key.split('/')[1] // key: 'files-for-case/1637751857539_Last_Encounter_Summary.pdf',
        const fileName = fileNamePathSplitted.replace(/[^a-zA-Z0-9._]/g, '')
        const signedUrl = await getSignedUrl('get', fileName)
        const encounterDocs: any = []
        if (files && files.length) {
          for (const file of files) {
            const fileNamePathSplitted = file.key.split('/')[1]
            const fileName = fileNamePathSplitted.replace(/[^a-zA-Z0-9._]/g, '')
            const signedUrl = await getSignedUrl('get', fileName)
            const fileData = {
              fileData: {
                name: fileName,
                type: file.type,
              },
              fileUrl: signedUrl.data.url,
            }
            encounterDocs.push(fileData)
          }
        }
        dispatch({
          type: SET_CERBO_LATEST_ENCOUNTER_SUMMARY,
          payload: {
            pdfFileObj: {
              name: fileName,
              type: 'application/pdf',
            },
            pdfUrl: signedUrl.data.url,
            files: encounterDocs,
            isLoading: false,
          },
        })
      } else {
        throw new Error('Error!')
      }
    } catch (errors) {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    } finally {
      dispatch({
        type: SET_CERBO_LATEST_ENCOUNTER_SUMMARY,
        payload: {
          pdfFileObj: null,
          pdfUrl: null,
          files: [],
          isLoading: false,
        },
      })
    }
  }

interface IGetLatestEncounterSummaryElation {
  practiceId: string
  elationPatientId: string
}
export const getLatestEncounterSummaryElation =
  ({ elationPatientId, practiceId }: IGetLatestEncounterSummaryElation) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch({
        type: SET_ELATION_LATEST_ENCOUNTER_SUMMARY,
        payload: {
          pdfFileObj: null,
          pdfUrl: null,
          isLoading: true,
        },
      })
      const res = await axios.get(
        `${getServerUrl()}/api/v1/elation/${practiceId}/patient/${elationPatientId}/getLatestEncounterSummaryElation`
      )
      const { status } = res.data
      if (status === '1') {
        const fileNamePathSplitted = res.data.data.key.split('/')[1] // key: 'files-for-case/1637751857539_Last_Encounter_Summary.pdf',
        const fileName = fileNamePathSplitted.replace(/[^a-zA-Z0-9._]/g, '')
        const signedUrl = await getSignedUrl('get', fileName)
        dispatch({
          type: SET_ELATION_LATEST_ENCOUNTER_SUMMARY,
          payload: {
            pdfFileObj: {
              name: fileName,
              type: 'application/pdf',
            },
            pdfUrl: signedUrl.data.url,
            isLoading: false,
          },
        })
      } else {
        throw new Error('Error!')
      }
    } catch (errors) {
      dispatch({
        type: GET_ERRORS,
        payload: errors,
      })
    } finally {
      dispatch({
        type: SET_ELATION_LATEST_ENCOUNTER_SUMMARY,
        payload: {
          pdfFileObj: null,
          pdfUrl: null,
          isLoading: false,
        },
      })
    }
  }
