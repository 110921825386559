import Step from '@mui/material/Step'

import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Link from '@mui/material/Link'
import StepLabel from '@mui/material/StepLabel'
import Stepper from '@mui/material/Stepper'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import textLogo from '../../shared/assets/images/text-logo.png'
import { useAppDispatch, useAppSelector } from '../../shared/store'
import {
  getAllInOnePractice,
  getSpecialtyFullList,
} from '../../shared/store/auth/actions'
import ExtensionDownload from './Forms/ExtensionDownload'
import HintIntegration from './Forms/HintIntegration'
import PaymentInfoForm from './Forms/PaymentInfoForm'
import SuccessPage from './Forms/SuccessPage'
import UserInfoForm from './Forms/UserInfoForm'
import { useRegisterStyles } from './RegisterPage.styles'

/********************************
 * This is designed to eventually replace the SignUpPage component for PCPs
 * Currently for admins and hint clinical users only
 *
 ********************************/

// function renderStepContent(step) {
//   switch (step) {
//     case 0:
//       return <UserInfoForm handleNext={handleNext}/>
//     //<Typography sx={{ mt: 2, mb: 1 }}>Step 1</Typography>
//     //return <AddressForm formField={formField} />;
//     case 1:
//       return <PaymentInfoForm />
//     //<div>Two</div>
//     //return <PaymentForm formField={formField} />;
//     case 2:
//       return <HintIntegration />
//     //return <ReviewOrder />;
//     case 3:
//       return <ExtensionDownload />
//     default:
//       return <div>An error occurred! Please email support@theahealth.com.</div>
//   }
// }

const RegisterPage: React.FC<
  RouteComponentProps<{
    isAdmin: string | undefined
    maxUser: string | undefined
    practiceId: string | undefined
    hash: string | undefined
  }>
> = ({ match, history }) => {
  const [formData, _] = React.useState({
    firstName: '',
    lastName: '',
    practice: '',
    role: '',
    specialtyId: null as null | string,
    email: '',
    password: '',
    password2: '',
    isAdmin: false,
    phoneNumber: '',
    errors: {},
  })
  console.log(formData)

  const auth = useAppSelector((state) => state.auth)
  const stateErrors = useAppSelector((state) => state.errors)
  console.log(stateErrors)
  const dispatch = useAppDispatch()

  const classes = useRegisterStyles()

  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const sessionId = urlParams.get('session_id')
  const step = urlParams.get('step')
  const clinicalPracticeId = urlParams.get('practiceId')
  const email = urlParams.get('email')
  const source = urlParams.get('source')
  const metadata = JSON.parse(urlParams?.get('metadata') || '{}')
  const firstName = metadata?.firstName
  const lastName = metadata?.lastName
  console.log(sessionId)
  console.log('LOGG')

  useEffect(() => {
    if (clinicalPracticeId) {
      getAllInOnePractice({ allInOneId: clinicalPracticeId }, history)
    }
  }, [clinicalPracticeId])

  useEffect(() => {
    document.addEventListener('keydown', handleHitEnter, true)
    return () => {
      document.removeEventListener('keydown', handleHitEnter, true)
    }
  }, [])

  useEffect(() => {
    dispatch(getSpecialtyFullList())
  }, [dispatch])

  useEffect(() => {
    if (auth.isAuthenticated) {
      console.log(history)
      //TODO handle redirect to inbox or appropriate sign up page here

      //history.push('/inbox/cases')
    }
  }, [auth.isAuthenticated, history])

  const handleHitEnter = (e: any) => {
    const ENTER_KEY_CODE = 13
    if (
      e.target.name === 'my-input' &&
      (e.key === 'Enter' || e.keyCode === ENTER_KEY_CODE)
    ) {
      e.stopPropagation()
    }
  }

  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1)
  // }

  // const handleReset = () => {
  //   setActiveStep(0)
  // }

  if (activeStep == 1 && sessionId) {
    console.log(activeStep)
    // get session status. If successful, set customer info and move to next step

    //Right now this breaks the back button functionality
    setActiveStep(2)
  }
  useEffect(() => {
    if (sessionId) {
      setActiveStep(2)
    }
  }, [sessionId])

  useEffect(() => {
    if (step) {
      // get session status. If successful, set customer info and move to next step
      setActiveStep(parseInt(step))
    }
  }, [sessionId])

  const steps = [
    'Create your account',
    'Enter billing information',
    'Integrate with Hint',
    'Download the extension',
  ]
  function renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <UserInfoForm
            handleNext={handleNext}
            firstName={firstName}
            lastName={lastName}
            email={email}
            clinicalPracticeId={clinicalPracticeId}
          />
        )
      case 1:
        return <PaymentInfoForm />
      case 2:
        return <HintIntegration />
      case 3:
        return <ExtensionDownload />
      case 4:
        return <SuccessPage source={source} />
      default:
        return (
          <div>An error occurred! Please email support@theahealth.com.</div>
        )
    }
  }

  return (
    <div>
      <Alert severity="info">
        <AlertTitle>Need Help?</AlertTitle>
        If you run into any issues or want help with setup,{' '}
        <Link href="https://calendly.com/harry-theahealth/30min">
          click here to book time with a member of our team.
        </Link>
      </Alert>
      <div className={classes.paper} style={{ display: 'flex' }}>
        <img
          src={textLogo}
          alt="required"
          style={{ width: '20%', marginBottom: 25 }}
        />
      </div>
      <div>
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          style={{ color: 'primary' }}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel style={{ color: '#13c1cf' }}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        {renderStepContent(activeStep)}
      </div>
    </div>
  )
}

export default RegisterPage
