import { Button, Link } from '@material-ui/core'
import Stack from '@mui/material/Stack'

import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from '../../../shared/store'
import { modifyUserData } from '../../../shared/store/auth/actions'
import { useRegisterStyles } from '../RegisterPage.styles'

export default function SuccessPage(props) {
  const dispatch = useDispatch()
  const history = useHistory()

  const auth = useAppSelector((state) => state.auth)

  const { source } = props
  useEffect(() => {
    if (source === 'chrome_extension') {
      if (auth && auth?.userdata) {
        // TODO check if activation step is 3 first before updating
        const userData = {
          userID: auth?.userdata?.id,
          activationStep: 4,
          active: true,
        }
        dispatch(modifyUserData(userData))
      }
    }
  }, [])

  const classes = useRegisterStyles()
  const redirectToMeetings = () => {
    window.location.href = 'https://calendly.com/harry-theahealth/30min'
  }
  const redirectToTheaInbox = () => {
    history.push('/')
  }

  return (
    <React.Fragment>
      <div className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          style={{ textAlign: 'center', margin: '10px 0' }}
        >
          Great work! You're all set up!
        </Typography>
        <Typography
          maxWidth="sm"
          style={{ textAlign: 'center', margin: '10px 0' }}
        >
          You can now create consults from anywhere in the web by clicking our
          log in your Chrome taskbar or in your EMR. If you run into any
          trouble, you can email <Link href="#">support@theahealth.com</Link>,
          read the help pages at this <Link href="#">link</Link>, or schedule
          some time for a training using the button below.
        </Typography>
        <Stack spacing={1}>
          <Button
            color="primary"
            variant="outlined"
            fullWidth
            style={{ margin: '10' }}
            onClick={redirectToMeetings}
          >
            {' '}
            Book a Training
          </Button>
          <Button
            color="primary"
            fullWidth
            className={classes.buttonGroup}
            variant="outlined"
            onClick={redirectToTheaInbox}
          >
            {' '}
            Go to Thea Inbox
          </Button>
        </Stack>
      </div>
    </React.Fragment>
  )
}
