import {
  Button,
  Container,
  CssBaseline,
  Grid,
  Link,
  TextField,
} from '@material-ui/core'
import classnames from 'classnames'
import MuiPhoneNumber from 'material-ui-phone-number'

import Typography from '@mui/material/Typography'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../shared/store'
import {
  fetchPracticeData,
  registerUser,
} from '../../../shared/store/auth/actions'
import { decodeString } from '../../../shared/utils/functions'
import { useRegisterStyles } from '../RegisterPage.styles'

export default function UserInfoForm(props, history) {
  const { firstName, lastName, email, clinicalPracticeId } = props

  console.log(decodeString('test'))
  console.log(fetchPracticeData(1))

  const [formData, setFormData] = React.useState({
    firstName: firstName,
    lastName: lastName,
    role: 'PCP',
    specialtyId: null as null | string,
    email: email,
    password: '',
    password2: '',
    isAdmin: true,
    phoneNumber: '',
    errors: {},
  })
  const auth = useAppSelector((state) => state.auth)

  console.log(formData)
  const stateErrors = useAppSelector((state) => state.errors)
  console.log(stateErrors)
  const dispatch = useAppDispatch()
  const classes = useRegisterStyles()

  const [errors, setErrors] = React.useState({
    firstName: '',
    lastName: '',
    role: '',
    specialtyId: '',
    email: '',
    password: '',
    password2: '',
    isAdmin: '',
    phoneNumber: '',
  })

  //   let isAdmin = match.params.isAdmin === 'true'
  //   let maxUser = parseInt(match.params.maxUser ? match.params.maxUser : '1')
  //   let practiceId = parseInt(
  //     match.params.practiceId ? match.params.practiceId : '0',
  //     10
  //   )

  //   if (match.params.hash && match.params.hash !== '') {
  //     const hash = decodeString(match.params.hash)
  //     const params = JSON.parse(hash)

  //     isAdmin = params.isAdmin === 'true'
  //     maxUser = parseInt(params.maxUser ? params.maxUser : '1')
  //     practiceId = parseInt(params.practiceId ? params.practiceId : '0', 10)
  //   }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    })
  }

  const practiceId = 0
  const maxUser = 1
  const isAdmin = true
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newUser = {
      practiceId,
      maxUser,
      isAdmin,
      firstName: formData.firstName,
      lastName: formData.lastName,
      role: formData.role,
      specialtyId: formData.specialtyId,
      practice: clinicalPracticeId,
      email: formData.email,
      password: formData.password,
      password2: formData.password2,
      phoneNumber: formData.phoneNumber,
      active: false,
      activationStep: 1,
      emrId: clinicalPracticeId,
    }
    console.log(newUser)

    dispatch(registerUser(newUser, history))
    props.handleNext()
  }

  // const getClinicalParams = () => {
  //   const queryString = window.location.search
  //   const urlParams = new URLSearchParams(queryString)
  //   const sessionId = urlParams.get('session_id')
  //   // const step = urlParams.get('step')
  //   console.log(sessionId)
  //   console.log('LOGG')
  // }

  useEffect(() => {
    document.addEventListener('keydown', handleHitEnter, true)
    return () => {
      document.removeEventListener('keydown', handleHitEnter, true)
    }
  }, [])

  //   useEffect(() => {
  //     dispatch(getSpecialtyFullList())
  //   }, [dispatch])

  useEffect(() => {
    if (auth.isAuthenticated) {
      console.log(history)
      //history.push('/inbox/cases')
    }
  }, [auth.isAuthenticated, history])

  useEffect(() => {
    const getErrors: any = stateErrors
    setErrors(getErrors)
  }, [stateErrors])

  const handleHitEnter = (e: any) => {
    const ENTER_KEY_CODE = 13
    if (
      e.target.name === 'my-input' &&
      (e.key === 'Enter' || e.keyCode === ENTER_KEY_CODE)
    ) {
      e.stopPropagation()
    }
  }

  const handlePhoneChange = (phone: string) => {
    if (phone) {
      phone = phone.replace(/ /g, '')
      setFormData({
        ...formData,
        phoneNumber: phone,
      })
    }
  }

  return (
    <React.Fragment>
      <Container component="main" maxWidth="sm" style={{ marginTop: 0 }}>
        <CssBaseline />
        <div className={classes.paper}>
          <div>
            <div style={{ marginBottom: 0 }}>
              <Typography
                component="h1"
                variant="h5"
                style={{ textAlign: 'center', margin: '0' }}
              >
                Create your account
              </Typography>
              <Typography style={{ textAlign: 'center', margin: '0' }}>
                We've pulled in some information from Hint Clincal about your
                practice. Please confirm those details below and add anything
                else that is missing. By creating your account you're agreeing
                to our <Link href="#">Terms of Service</Link> and{' '}
                <Link href="#">BAA</Link>.
              </Typography>
            </div>
            <form className={classes.form} noValidate onSubmit={onSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="fname"
                    name="firstName"
                    value={formData.firstName}
                    variant="outlined"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                    onChange={onChange}
                    className={classnames('', {
                      error: errors.firstName,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.firstName}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="lname"
                    onChange={onChange}
                    value={formData.lastName}
                    className={classnames('', {
                      error: errors.lastName,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.lastName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={formData.email}
                    onChange={onChange}
                    className={classnames('', {
                      error: errors.email,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.email}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <MuiPhoneNumber
                    name="phoneNumber"
                    required
                    label="Phone Number"
                    defaultCountry={'us'}
                    variant="outlined"
                    onChange={handlePhoneChange}
                    style={{ width: '100%' }}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.phoneNumber}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={onChange}
                    className={classnames('', {
                      error: errors.password,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.password}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    name="password2"
                    label="Confirm Password"
                    type="password"
                    id="password2"
                    autoComplete="current-password"
                    onChange={onChange}
                    className={classnames('', {
                      error: errors.password2,
                    })}
                  />
                  <Typography color="error" className={classes.errorText}>
                    {errors.password2}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography //Not removing them for now
                    color="error"
                    className={classes.errorText}
                  >
                    {errors.isAdmin}
                  </Typography>
                </Grid>
                {isAdmin === true && (
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                    className={classes.submit}
                  >
                    Proceed to Billing Info
                  </Button>
                )}
              </Grid>

              {isAdmin !== true && (
                <Button //Has to be hidden for Admin or by Default TODO remove one of these on better wifi
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Sign Up
                </Button>
              )}
              <Grid container justifyContent="flex-end">
                <Grid item style={{ marginTop: 20 }}>
                  <Link style={{ padding: 0 }} href="/login" variant="body2">
                    <Typography>Already have an account? Sign in</Typography>
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </div>
      </Container>
    </React.Fragment>
  )
}
