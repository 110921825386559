import { Hidden, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import DescriptionIcon from '@material-ui/icons/Description'
import HighlightOff from '@material-ui/icons/HighlightOff'
import SendIcon from '@material-ui/icons/Send'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import DragDropPlugin from '../../../components/NewCase/DragDropPlugin'
import { sendCase } from '../../store/case/actions'
import { sendAmplitudeData } from '../../utils/amplitude'
import { amplitudeEvents } from '../../utils/constant'
import { encodeString, generateCaseID } from '../../utils/functions'
import { uploadFile } from '../../utils/s3'

const styles = () => ({
  helpIcon: {
    position: 'fixed',
    cursor: 'pointer',
    opacity: 1,
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    bottom: 33,
    right: 33,
    width: 36,
    height: 36,
    borderRadius: '100%',
    fontSize: 20,
    boxShadow:
      'rgba(15, 15, 15, 0.1) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 2px 4px',
    zIndex: 101,
    padding: '5px 14px',
  },
  intercomMessengerMain: {
    zIndex: 101,
    position: 'fixed',
    bottom: 20,
    right: 20,
    height: 'calc(100% - 90px)',
    width: 376,
    minHeight: 250,
    maxHeight: 704,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 5px 40px',
    opacity: 1,
    borderRadius: 8,
    overflow: 'hidden',
  },
  intercomMessenger: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    position: 'absolute',
    overflow: 'hidden',
    inset: 0,
  },
  intercomCloseMain: {
    position: 'absolute',
    width: 51,
    height: 51,
    top: 0,
    right: 0,
    backgroundPosition: 'center center',
    margin: '12px 8px 12px 2px',
    cursor: 'pointer',
    zIndex: 101,
  },
  intercomCloseWrap: {
    width: 51,
    height: 51,
    margin: '0px auto',
    borderRadius: 8,
    alignItems: 'center',
    display: 'flex',
  },
  closeIcon: {
    margin: '0px auto',
    width: 12,
    height: 12,
  },
  intercomHeader: {
    position: 'relative',
    height: 75,
    color: 'white',
    background:
      'linear-gradient(135deg, rgb(19, 193, 207) 0%, rgb(19, 193, 207) 100%)',
  },
  intercomHeaderView: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    outlineOffset: -5,
  },
  intercomContent: {
    position: 'relative',
    flex: '1 1 0%',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 21px 4px -20px inset',
  },
  intercomBottom: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: '0px 0px 6px 6px',
  },
  intercomComposer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    minHeight: 56,
    maxHeight: 200,
    borderTop: '1px solid rgb(230, 230, 230)',
  },
  intercomTextarea: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'rgb(0, 0, 0)',
    resize: 'none',
    border: 'none',
    outlineOffset: -5,
    outline: 'none',
    fontFamily: 'inherit',
    boxSizing: 'border-box',
    padding: '18px 60px 18px 20px',
    width: '100%',
    height: '100%',
    fontSize: 14,
    fontWeight: 'normal',
    lineHeight: 1.33,
    backgroundColor: 'rgb(255, 255, 255)',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'break-word',
  },
  intercomHloib: {
    position: 'absolute',
    top: 0,
    right: 10,
  },
  intercomSendButton: {
    position: 'relative',
    float: 'left',
    display: 'inline-block',
    cursor: 'pointer',
    opacity: '0.8',
    height: 51,
    marginTop: 2,
    width: 30,
    border: 'none',
    background: 'none',
    outline: 'none',
  },
  conversationBody: {
    position: 'absolute',
    inset: 0,
  },
  bodyParts: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    inset: 0,
    overflow: 'hidden scroll',
    outlineOffset: -5,
    bottom: 56,
    padding: 12,
  },
  mainGroup: {
    display: 'inline-block',
    width: '100%',
  },
  blockParagraph: {
    margin: 10,
    display: 'flex',
    padding: 15,
    maxWidth: 300,
    borderRadius: 10,
    lineHeight: 1.5,
  },
  senderBlock: {
    float: 'right',
    backgroundColor: '#EBF2FB',
  },
  boatBlock: {
    float: 'left',
    backgroundColor: '#F4F4F4',
  },
  thumbFiles: {
    position: 'absolute',
    bottom: 60,
    left: 10,
  },
  thumbImg: {
    marginRight: 5,
    height: 48,
  },
  thumbContain: {
    float: 'left',
    position: 'relative',
  },
  chatThumbContain: {
    width: '100%',
    float: 'right',
    textAlign: 'right',
  },
  chatThumbImg: {
    marginRight: 5,
    height: 80,
  },
  senderLabel: {
    width: '100%',
    textAlign: 'right',
    float: 'right',
    marginRight: 12,
  },
  boatLabel: {
    width: '100%',
    float: 'left',
    marginLeft: 12,
  },
  thumbIcon: {
    position: 'absolute',
    right: -7,
    top: -17,
    cursor: 'pointer',
  },
  sideIcon: {
    height: 22,
    color: 'rgb(102, 119, 140)',
  },
})

export class HelpButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      message: '',
      messageList: [],
      displayMain: 'none',
      files: [],
      newFiles: [],
    }
    this.scrollToBottom = this.scrollToBottom.bind(this)
  }

  componentDidMount() {
    window.addEventListener('dragenter', this.handleDragEnter)
    this.scrollToBottom()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  handleDragEnter(event) {
    if (event.target.dataset.area === 'drag-area') {
      let div = document.getElementById('drag-drop-div')
      div.style.visibility = 'visible'
    }
  }

  helpClick = () => {
    this.setState({ displayMain: 'block' })
    sendAmplitudeData(
      this.props.auth.email,
      amplitudeEvents.SUPPORT_POPUP,
      this.props.auth
    )
  }

  closeClick = () => {
    this.setState({ displayMain: 'none' })
  }

  onInputChange = (e) => {
    this.setState({
      message: e.target.value,
    })
  }

  handleSubmit = async () => {
    const { message, files } = this.state

    if (message === '' && files.length === 0) {
      return
    }
    let newMessage = {
      userId: this.props.auth.id,
      userType: this.props.auth.userType,
      name: this.props.auth.firstName + ' ' + this.props.auth.lastName,
      email: this.props.auth.email,
      message,
      files,
      type: 'sender',
    }

    await this.setState((prevState) => ({
      messageList: [...prevState.messageList, newMessage],
    }))

    let respMessage = {
      type: 'boat',
      message:
        'Thank you for reaching out! A member of our team will respond via your Thea Health Inbox',
    }

    setTimeout(() => {
      this.setState((prevState) => ({
        messageList: [...prevState.messageList, respMessage],
      }))
    }, 1000)

    this.setState({
      message: '',
      files: [],
    })

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        const [promiseFile] = await uploadFile(files[i])
        this.setState({ newFiles: [...this.state.newFiles, promiseFile] })
      }
    }

    const currentTime = new Date()
    const currentTimestamp = currentTime.getTime()
    const caseId = generateCaseID(this.props.auth.firstName, currentTimestamp)

    const { newFiles } = this.state

    const newCase = {
      caseId,
      providerId: this.props.auth.id,
      practiceId: this.props.auth.practiceId,
      specialtyIds: [9999], // specialtyId 9999 is for support
      questions: encodeString(message),
      files: newFiles && newFiles.length ? newFiles : null,
      isRead: true,
      isDeleted: false,
      patientConsentGiven: true,
      email: this.props.auth.email,
      patientName: this.props.auth.firstName + ' ' + this.props.auth.lastName,
      patientId: 0,
      userType: this.props.auth.userType,
    }
    console.log(newCase)
    this.props.sendCase(newCase)
    const eventData = { ...this.props.auth, ...{ caseId } }
    sendAmplitudeData(
      this.props.auth.email,
      amplitudeEvents.SUPPORT_CASE_CREATED,
      eventData
    )

    this.setState({ newFiles: [] })
  }

  handleFileUpload = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    )

    if (files && files.length) {
      for (let i = 0; i < files.length; i++) {
        await this.setState({ files: [...this.state.files, files[i]] })
      }
    }
  }

  removeFile = (e, key) => {
    let files = this.state.files
    files.splice(key, 1)
    this.setState({ files })
  }

  selectFiles = (event) => {
    this.inputFileElement.click()
  }

  scrollToBottom = () => {
    if (this.messageBody)
      this.messageBody.scrollTo(0, this.messageBody.scrollHeight)
  }

  render() {
    const { classes } = this.props
    const { messageList, files } = this.state

    const acceptedFileTypes =
      'image/*, text/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'

    return (
      <div>
        <Hidden only={['xs']}>
          <div className={classes.helpIcon} onClick={this.helpClick}>
            ?
          </div>
        </Hidden>
        <div
          className={classes.intercomMessengerMain}
          style={{ display: this.state.displayMain }}
        >
          <div className={classes.intercomMessenger}>
            <div className={classes.intercomCloseMain}>
              <div
                className={classes.intercomCloseWrap}
                onClick={this.closeClick}
              >
                <svg className={classes.closeIcon}>
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <g
                      transform="translate(-340.000000, -32.000000)"
                      fill="#ffffff"
                    >
                      <polygon points="350.656537 44 346 39.343463 341.343463 44 340 42.656537 344.656537 38 340 33.343463 341.343463 32 346 36.656537 350.656537 32 352 33.343463 347.343463 38 352 42.656537"></polygon>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
            <div className={classes.intercomHeader}>
              <div>
                <div className={classes.intercomHeaderView}>
                  <div>
                    <div style={{ padding: 18 }}>
                      <h2 className="intercom" style={{ fontSize: 16 }}>
                        TheaHealth
                      </h2>
                      <div className="intercom">
                        <span className="intercom" style={{ fontSize: 14 }}>
                          We're here to help!
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.intercomContent} data-area="drag-area">
              <DragDropPlugin handleFileUpload={this.handleFileUpload} />

              {messageList.length > 0 ? (
                <div className={classes.conversationBody} data-area="drag-area">
                  <div
                    className={classes.bodyParts}
                    data-area="drag-area"
                    ref={(el) => {
                      this.messageBody = el
                    }}
                  >
                    {messageList.map((item, i) => {
                      if (item.type === 'sender') {
                        return (
                          <div
                            className={classes.mainGroup}
                            data-area="drag-area"
                            key={i}
                          >
                            <label className={classes.senderLabel}>Me</label>
                            {item.message && (
                              <div
                                className={`${classes.blockParagraph} ${classes.senderBlock}`}
                                data-area="drag-area"
                              >
                                {item.message}
                              </div>
                            )}
                            <div className={classes.chatThumbContain}>
                              {item.files.map((file, i) => {
                                if (file.type.includes('image')) {
                                  return (
                                    <img
                                      className={classes.chatThumbImg}
                                      src={file.preview}
                                      title={file.name}
                                      alt={file.name}
                                    />
                                  )
                                } else {
                                  return (
                                    <DescriptionIcon
                                      titleAccess={file.name}
                                      style={{ fontSize: 80 }}
                                    />
                                  )
                                }
                              })}
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <div
                            className={classes.mainGroup}
                            data-area="drag-area"
                            key={i}
                          >
                            <label className={classes.boatLabel}>Support</label>
                            <div
                              className={`${classes.blockParagraph} ${classes.boatBlock}`}
                              data-area="drag-area"
                            >
                              {item.message}
                            </div>
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
              ) : (
                <div></div>
              )}

              {files.length > 0 && (
                <div className={classes.thumbFiles}>
                  {files.map((file, i) => {
                    if (file.type.includes('image')) {
                      return (
                        <div className={classes.thumbContain}>
                          <img
                            className={classes.thumbImg}
                            src={file.preview}
                            title={file.name}
                            alt={file.name}
                          />
                          <HighlightOff
                            className={classes.thumbIcon}
                            onClick={(e) => this.removeFile(e, i)}
                          />
                        </div>
                      )
                    } else {
                      return (
                        <div className={classes.thumbContain}>
                          <DescriptionIcon
                            titleAccess={file.name}
                            style={{ fontSize: 48 }}
                          />
                          <HighlightOff
                            className={classes.thumbIcon}
                            onClick={(e) => this.removeFile(e, i)}
                          />
                        </div>
                      )
                    }
                  })}
                </div>
              )}

              <div className={classes.intercomBottom}>
                <div className={classes.intercomComposer}>
                  <textarea
                    className={classes.intercomTextarea}
                    placeholder="Start typing..."
                    onChange={this.onInputChange}
                    value={this.state.message}
                  ></textarea>
                  <div className={classes.intercomHloib}>
                    <IconButton
                      className={classes.intercomSendButton}
                      onClick={this.selectFiles}
                    >
                      <input
                        type="file"
                        accept={acceptedFileTypes}
                        multiple
                        ref={(input) => (this.inputFileElement = input)}
                        onChange={(e) => {
                          this.handleFileUpload([...e.target.files])
                        }}
                        style={{ display: 'none' }}
                      />
                      <AttachFileIcon className={classes.sideIcon} />
                    </IconButton>
                    <IconButton
                      className={classes.intercomSendButton}
                      onClick={this.handleSubmit}
                    >
                      <SendIcon className={classes.sideIcon} />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HelpButton.propTypes = {
  auth: PropTypes.object.isRequired,
  sendCase: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  auth: state.auth.userdata,
})

export default connect(mapStateToProps, { sendCase })(
  withStyles(styles)(HelpButton)
)
