import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fab,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import HighlightOff from '@material-ui/icons/HighlightOff'
import classnames from 'classnames'
import heic2any from 'heic2any'
import produce from 'immer'
import moment from 'moment'
import React, { useState } from 'react'

import DiagnosisIcon from '../../../shared/assets/icons/DiagnosisIcon.svg'
import ViewFileThumbnail from '../../../shared/components/ViewFileThumbnail/ViewFileThumbnail'
import { useAppDispatch, useAppSelector } from '../../../shared/store'
import { modifyCase, setCurrentCase } from '../../../shared/store/case/actions'
import { sendAmplitudeData } from '../../../shared/utils/amplitude'
import { amplitudeEvents } from '../../../shared/utils/constant'
import { isEmpty } from '../../../shared/utils/functions'
import { removeFileFromS3, uploadFile } from '../../../shared/utils/s3'
import DropzonePlugin from '../../NewCase/DropzonePlugin'
import AttachCard from '../AttachCard'
import { useInsideMessageRightCardStyles } from './InsideMessageRightCard.styles'

interface IFileData {
  name: string
  type: string
}
interface IFileState {
  fileData: IFileData
  fileUrl: string
}

const InsideMessageRightCard: React.FC<any> = ({ expanded }) => {
  const [openUploadFileModal, setOpenUploadFileModal] = useState(false)
  const [files, setFiles] = useState<IFileState[]>([])
  const [fileToRemove, setFileToRemove] = useState<IFileState>()
  const [isLoading, setIsLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  const currentCase = useAppSelector((state) => state.case.currentCase)
  const caseLoader = useAppSelector((state) => state.case.caseLoader)
  const userdata = useAppSelector((state) => state.auth.userdata)

  const dispatch = useAppDispatch()

  const classes = useInsideMessageRightCardStyles()

  const renderFilesView = (files: IFileState[]) => {
    const viewers: any = []
    for (let i = 0; i < files.length; i += 2) {
      viewers.push(
        <Grid container spacing={3} key={i}>
          {files[i] !== undefined && (
            <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
              <AttachCard
                fileState={files[i]}
                fileIndex={i}
                actionIcon={
                  <IconButton
                    onClick={() => openDialog(files[i])}
                    className={classes.delIcon}
                  >
                    <HighlightOff />
                  </IconButton>
                }
              />
            </Grid>
          )}
          {files[i + 1] !== undefined && (
            <Grid item xs={12} sm={6} style={{ position: 'relative' }}>
              <AttachCard
                fileState={files[i + 1]}
                fileIndex={i + 1}
                actionIcon={
                  <IconButton
                    onClick={() => openDialog(files[i + 1])}
                    className={classes.delIcon}
                  >
                    <HighlightOff />
                  </IconButton>
                }
              />
            </Grid>
          )}
        </Grid>
      )
    }
    return viewers
  }

  const handleFilesUpload = async (newFiles: any) => {
    if (!newFiles.length) {
      return
    } else {
      setIsLoading(true)
      for (let i = 0; i < newFiles.length; i++) {
        let newFile = newFiles[i]
        const fileExtension = newFile.name.split('.').pop()
        if (fileExtension === 'heic' || fileExtension === 'heif') {
          const blobImage = await heic2any({
            blob: newFile,
            toType: 'image/png',
            quality: 1,
          })
          const fileName = newFile.name.replace(/heic|heif|HEIC|HEIF/g, 'png')
          const newImage = new File([blobImage as any], fileName, {
            lastModified: new Date().getTime(),
            type: 'image/png',
          })
          newFile = newImage
        }
        const [fileData, fileUrl] = await uploadFile(newFile)
        setFiles(
          produce((draft) => {
            draft.push({ fileData, fileUrl })
          })
        )
      }
      setIsLoading(false)
    }
  }

  const handleSaveNewFilesToCase = () => {
    const newFiles = [
      ...(currentCase.caseData?.files?.map(
        (item: IFileState) => item.fileData
      ) || []),
      ...files?.map((item: IFileState) => item.fileData),
    ]
    const newCaseData = {
      caseId: currentCase.caseData.caseId,
      lastUpdatedById: userdata.id,
      isRead: true,
      questions: currentCase.caseData.questions,
      isDeleted: false,
      currentUserRole: userdata.userType,
      files: newFiles.length ? newFiles : null,
    }

    dispatch(modifyCase(newCaseData))

    const eventData = {
      ...userdata,
      ...{ caseId: currentCase.caseData.caseId },
    }
    sendAmplitudeData(
      userdata.email,
      amplitudeEvents.CASE_FILE_UPLOAD,
      eventData
    )

    setOpenUploadFileModal(false)
    setFiles([])
  }

  const removeFile = async (file: IFileData) => {
    const resp = await removeFileFromS3(file)
    if (resp) {
      setFiles(
        files.filter((item: IFileState) => file.name !== item.fileData.name)
      )
    }
  }

  const openDialog = (file: IFileState) => {
    setOpenConfirm(true)
    setFileToRemove(file)
  }

  const handleConfirmClose = () => {
    setOpenConfirm(false)
    setFileToRemove(undefined)
  }

  const handleRemoveFile = async () => {
    if (!fileToRemove) {
      return
    } else {
      const newFiles = currentCase.caseData.files
        .filter(
          (file: IFileState) =>
            file.fileData.name !== fileToRemove.fileData.name
        )
        .map((item: IFileState) => item.fileData)

      const newCaseData = {
        caseId: currentCase.caseData.caseId,
        lastUpdatedById: userdata.id,
        isRead: true,
        currentUserRole: userdata.userType,
        isDeleted: currentCase.caseData.isDeleted,
        files: newFiles.length ? newFiles : null,
      }

      dispatch(modifyCase(newCaseData))
      setOpenConfirm(false)
      setFileToRemove(undefined)

      const eventData = {
        ...userdata,
        ...{ caseId: currentCase.caseData.caseId },
      }
      sendAmplitudeData(
        userdata.email,
        amplitudeEvents.CASE_FILE_REMOVE,
        eventData
      )

      const currentCaseCondition = {
        caseId: currentCase.caseData.caseId,
        currentUserRole: userdata.userType,
      }
      setTimeout(() => {
        setCurrentCase(currentCaseCondition)
      }, 100)
    }
  }

  if (expanded) {
    return (
      <Card
        className={classnames(classes.rightCard, classes.rightCardCollapsed)}
      >
        <img alt="required" src={DiagnosisIcon} />
      </Card>
    )
  } else {
    return (
      <Card
        className={classes.rightCard}
        data-selector="inside-message-right-card"
      >
        <div style={{ display: 'flex', backgroundColor: 'white' }}>
          <Typography className={classes.caseInfoText}>
            Case Information
          </Typography>
          {userdata.userType === 'PCP' &&
            currentCase.caseData &&
            !currentCase.caseData.isDeleted && (
              <div style={{ marginLeft: 'auto' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={caseLoader}
                  onClick={() => setOpenUploadFileModal(true)}
                  className={classes.uploadButton}
                >
                  Upload Files
                </Button>
              </div>
            )}
        </div>

        {/* UPLOAD FILES TO S3 AND DISPLAY IN MODAL */}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={openUploadFileModal}
          // onClose={handleClose}
          style={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className={classes.modalContainer}>
            <Card className={classes.modalCard}>
              <div style={{ height: '80%', minHeight: 100 }}>
                <DropzonePlugin
                  removeFile={removeFile}
                  handleFileUpload={handleFilesUpload}
                  files={files}
                />
              </div>

              <Divider />

              <aside
                style={{
                  overflow: 'auto',
                  backgroundColor: 'white',
                  margin: '10px',
                }}
              >
                {isLoading && (
                  <CircularProgress
                    style={{
                      marginTop: 10,
                      marginLeft: 32,
                      alignSelf: 'center',
                    }}
                    size={20}
                    disableShrink
                  />
                )}
                <GridList cols={3.5}>
                  {files.length &&
                    files.map((file: IFileState, i: number) => {
                      return (
                        <GridListTile key={file.fileUrl}>
                          <ViewFileThumbnail
                            fileData={file.fileData}
                            fileUrl={file.fileUrl}
                          />
                          <GridListTileBar
                            title={file.fileData.name.slice(13)}
                            titlePosition="top"
                            actionIcon={
                              <IconButton
                                onClick={() => removeFile(file.fileData)}
                                aria-label={`star`}
                              >
                                <HighlightOff
                                  style={{ marginLeft: 5, color: 'white' }}
                                />
                              </IconButton>
                            }
                          />
                        </GridListTile>
                      )
                    })}
                </GridList>
              </aside>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveNewFilesToCase}
                disabled={!files.length}
                data-selector="upload-btn"
              >
                Upload
              </Button>
            </Card>
            <div
              style={{
                justifyContent: 'center',
                display: 'flex',
                paddingTop: 5,
              }}
            >
              <Fab
                variant="round"
                onClick={() => setOpenUploadFileModal(false)}
              >
                <CloseIcon />
              </Fab>
            </div>
          </div>
        </Modal>

        {/* DISPLAY PARTIENT NAME & DOB */}
        {!isEmpty(currentCase) && (
          <Typography className={classes.nameText} component="div">
            <p>Patient: {currentCase.caseData?.patientName}</p>
            {currentCase.patientData?.dob && (
              <p>
                DOB: {moment(currentCase.patientData?.dob).format('MM/DD/YYYY')}
              </p>
            )}
          </Typography>
        )}

        {/* DISPLAY CURRENT CASE FILES */}
        {currentCase.caseData !== undefined &&
          currentCase.caseData.files &&
          currentCase.caseData.files.length >= 1 &&
          renderFilesView(currentCase.caseData.files)}

        {/* CONFIRM DIALOG TO REMOVE FILES FROM CURRENT CASE */}
        <Dialog
          open={openConfirm}
          TransitionComponent={Slide}
          keepMounted
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {'Please confirm'}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to remove this file?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleConfirmClose} color="primary">
              No
            </Button>
            <Button onClick={handleRemoveFile} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    )
  }
}

export default InsideMessageRightCard
